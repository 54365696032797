import { MaterialGroup, sandwichHasHPL } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMaterialGroupErrorStore, usePartsErrorStore } from '@/api/errors';
import { getMaterial } from '@/api/materials';
import {
  activeGroupSelector,
  makeTitle,
  useActiveGroup,
  useActiveGroupGrainDirection,
  useEdgebandSelected,
  useMaterialGroupState,
} from '@/api/materialsGroup';
import { selectActions, useCutlistParts, useCutlistState } from '@/api/store';
import { EdgesTooltip } from '@/blocks/EdgesTooltip';
import { useCurrentFeatures } from '@/theme';

import { Errors, PartErrors } from '../Errors';
import { EmptyTableNudge } from './EmptyTableNudge';
import { MaterialGroupActions } from './MaterialGroupActions';
import { MaterialGroupHeader } from './MaterialGroupHeader';
import { MaterialGroupNotes } from './MaterialGroupNotes';
import styles from './styles.module.css';
import { TableRow } from './TableRow';

export const PartTable = () => {
  return (
    <form className={styles.orderSection} name="parts">
      <Table />
    </form>
  );
};

const tableHeaders = [
  { labelKey: 'field.partName.label', id: 'label', width: '80px' },
  { labelKey: 'field.quantity.label' },
  { labelKey: 'field.length.label' },
  { labelKey: 'field.width.label' },
  { labelKey: 'field.edgeband.label', id: 'edgebanding', colSpan: 5 },
  { labelKey: 'field.edgeProfile.label', id: 'edgeProfile', colSpan: 5 },
  { labelKey: 'field.grainDirection.label', id: 'grain' },
  { labelKey: 'field.labeling', id: 'createLabel' },
  { labelKey: '', width: '50px' },
];

const useHeaders = () => {
  const activeGroup = useMaterialGroupState(activeGroupSelector);
  const { hasEdgeProfiling, hasCreateLabels } = useCurrentFeatures();
  const hasGrainDirection = useActiveGroupGrainDirection();

  return tableHeaders.filter(({ id }) => {
    if (id === 'edgebanding') return Boolean(activeGroup?.edgeband);
    if (id === 'edgeProfile') return hasEdgeProfiling;
    if (id === 'grain') return hasGrainDirection;
    if (id === 'createLabel') return hasCreateLabels === 'part';
    return true;
  });
};

export const SkeletonTable = () => {
  const groups = useMaterialGroupState((state) => state.groups);
  const headers = useHeaders();
  return (
    <form className={styles.orderSection} name="parts">
      {groups.map((g) => (
        <section key={g.id} className={styles.partSection}>
          <table>
            <Head headers={headers} />
            <SkeletonBody headers={headers} />
          </table>
        </section>
      ))}
    </form>
  );
};

const Table = () => {
  const groups = useMaterialGroupState((state) => state.groups);
  const activeTab = useMaterialGroupState((state) => state.activeGroup);
  const activeGroup = useActiveGroup();
  const headers = useHeaders();

  const { t } = useTranslation();
  const { addGroupError, removeGroupErrors } = useMaterialGroupErrorStore();
  const { addPartError, removePartErrorsByField } = usePartsErrorStore();

  const parts = useCutlistParts();

  React.useEffect(() => {
    groups.forEach((group) => {
      const groupParts = parts.filter((p) => p.groupId === group.id);
      groupParts.forEach((part) => {
        removePartErrorsByField(part.id, 'partType');
        if (
          group.sheetSizeSelection === 'automatic' &&
          ['strip', 'sheet'].includes(part.partType)
        ) {
          addPartError(
            part.id,
            'partType',
            t(
              `cutlist-form.errors.automaticSheetSizeWarning.${
                part.partType === 'strip' ? 'addStrip' : 'addFullSheet'
              }`
            )
          );
        }
      });

      removeGroupErrors(group.id);
      if (group.core1 || group.core2 || group.topHpl || group.bottomHpl) {
        // Create error for material groups with single side HPLs. We do not allow single side gluing.
        const shouldHaveHPL = sandwichHasHPL(group.materialSandwichType);
        if (shouldHaveHPL && (!group.topHpl || !group.bottomHpl)) {
          addGroupError(
            group.id,
            t('cutlist-form.errors.oneSideHPLError', {
              materialGroupIndex: makeTitle(group),
            })
          );
        }
        if (shouldHaveHPL && group.core1) {
          const core1Material = getMaterial(group.core1);
          if (!core1Material?.gluingAndPressingAllowed) {
            addGroupError(
              group.id,
              t('cutlist-form.errors.coreMaterialNotAllowedForGluing', {
                materialGroupIndex: makeTitle(group),
              })
            );
          }
        }
        if (!group.core1) {
          addGroupError(
            group.id,
            t('cutlist-form.errors.missingCoreError', {
              materialGroupIndex: makeTitle(group),
            })
          );
        }

        // if there is edgebanding selected on the material group
        // but no edgebanding checkboxes are selected at part level
        const edgebandingCheckboxSelected = groupParts.some((p) => {
          if (!p.edgebanding) return false;
          return Object.values(p.edgebanding).some((v) => Boolean(v));
        });
        if (Boolean(group.edgeband) && !edgebandingCheckboxSelected) {
          addGroupError(
            group.id,
            t('cutlist-form.errors.edgebandingNotSelected', {
              materialGroupIndex: makeTitle(group),
            })
          );
        }

        // if there is edge profiling selected on the material group
        // but no parts have edge profiling checkboxes selected
        const edgeProfileCheckboxSelected = groupParts.some((p) => {
          if (!p.edgeProfile) return false;
          return Object.values(p.edgeProfile).some((v) => v !== 'none');
        });
        if (
          group.edgeProfile &&
          group.edgeProfile !== 'none' &&
          !edgeProfileCheckboxSelected
        ) {
          addGroupError(
            group.id,
            t('cutlist-form.errors.edgeProfilingNotSelected', {
              materialGroupIndex: makeTitle(group),
            })
          );
        }
      }
    });
  }, [groups, parts]);

  return (
    <>
      {groups
        .filter((g) => !activeTab || g.id === activeTab)
        .map((g) => {
          return (
            <section
              key={g.id}
              className={styles.partSection}
              id={`section-${g.id}`}
            >
              <MaterialGroupHeader group={g} />

              {activeGroup?.type && activeGroup?.core1 && (
                <>
                  <PartErrors />
                  <table>
                    <Head headers={headers} />
                    <Body group={g} />
                  </table>

                  <EmptyTableNudge group={g} />
                  <MaterialGroupActions group={g} />
                  <p
                    style={{
                      color: 'var(--gray-8)',
                      fontSize: '0.75rem',
                      padding: 'var(--space-xs) 0',
                    }}
                  >
                    {t('cutlist-form.info.dimensions')}
                  </p>
                  <Errors />

                  <MaterialGroupNotes group={g} />
                </>
              )}
            </section>
          );
        })}
    </>
  );
};

const Head = ({ headers }: { headers: typeof tableHeaders }) => {
  const { edgebandingUI, hasEdgeProfiling, hasCreateLabels } =
    useCurrentFeatures();
  const { t } = useTranslation();
  const isEdgebandSelected = useEdgebandSelected();
  const hasGrainDirection = useActiveGroupGrainDirection();

  const hasEdgebanding = isEdgebandSelected && edgebandingUI;

  const headerText = (key?: string) => (key ? t(`cutlist-form.${key}`) : '');

  return (
    <thead>
      <tr>
        {headers.map((h, i) => {
          const edgesHeader = (
            <th
              key={h.labelKey || i}
              style={{ minWidth: h.width }}
              colSpan={h.colSpan}
            >
              <div>
                {t(`cutlist-form.${h.labelKey}`)}
                <EdgesTooltip />
              </div>
            </th>
          );

          switch (h.id) {
            case 'edgebanding':
              if (!hasEdgebanding) return null;
              return edgesHeader;
            case 'edgeProfile':
              if (!hasEdgeProfiling) return null;
              return edgesHeader;
          }

          return (
            <th key={h.labelKey || i} style={{ minWidth: h.width }} colSpan={1}>
              {headerText(h.labelKey)}
            </th>
          );
        })}
      </tr>
      {(hasEdgebanding || hasEdgeProfiling) && (
        <tr>
          <th />
          <th />
          <th />
          <th />
          <th>{t('cutlist-form.field.edgebanding.sides.l1')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.l2')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.w1')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.w2')}</th>
          <th />
          {hasGrainDirection && <th />}
          {hasCreateLabels === 'part' && <th />}
          <th />
        </tr>
      )}
    </thead>
  );
};

const SkeletonBody = ({ headers }: { headers: typeof tableHeaders }) => {
  const parts = [{ id: 1 }, { id: 2 }, { id: 3 }];

  return (
    <tbody>
      {parts.map((part) => {
        return <SkeletonRow headers={headers} key={part.id} />;
      })}
    </tbody>
  );
};

const SkeletonRow = ({ headers }: { headers: typeof tableHeaders }) => (
  <tr>
    {headers.map((h) => {
      return (
        <td
          key={h.labelKey}
          className={styles.loading}
          colSpan={h.colSpan ?? 1}
        >
          <div className={styles.bar}></div>
        </td>
      );
    })}
  </tr>
);

const Body = ({ group }: { group: MaterialGroup }) => {
  const ref = React.useRef(null);
  const shouldFocusLast = React.useRef(false);
  const { addPart } = useCutlistState(selectActions);

  const parts = useCutlistState((state) =>
    state.parts.filter((part) => part.groupId === group.id)
  );

  React.useEffect(() => {
    if (!ref.current) return;
    const element = ref.current as HTMLElement;

    const onFocusOut = (e: FocusEvent) => {
      if (element.contains(e.relatedTarget as HTMLElement)) return;
      const time = e.timeStamp;
      window.addEventListener(
        'keyup',
        (e) => {
          if (e.key === 'Tab' && e.timeStamp - time < 200) {
            addPart(group);
            shouldFocusLast.current = true;
          }
        },
        { once: true }
      );
    };
    element.addEventListener('focusout', onFocusOut);
    return () => {
      element.removeEventListener('focusout', onFocusOut);
    };
  }, [ref]);

  React.useEffect(() => {
    if (!ref.current) return;
    if (!shouldFocusLast.current) return;

    const element = ref.current as HTMLElement;
    const lastInput = element.querySelector(
      'tr:last-child input:first-of-type'
    ) as HTMLInputElement;
    lastInput?.focus();
    shouldFocusLast.current = false;
  }, [parts, ref, shouldFocusLast]);

  return (
    <tbody ref={ref}>
      {parts.map((part) => {
        return <TableRow part={part} key={part.id} />;
      })}
    </tbody>
  );
};
