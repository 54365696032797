import { CutlistOrder } from '@cutr/constants/cutlist';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConfirmationDialog } from '@/blocks/ConfirmationDialog';
import { OrderCard } from '@/blocks/OrderCard';
import { Button } from '@/primitives/Button';
import { Icon, Plus } from '@/primitives/Icons';
import {
  useCreateEmptyCutlist,
  useDeleteCutlist,
  useDuplicateCutlist,
} from '@/queries/crud';
import { useCutlists } from '@/queries/cutlists';
import { useCurrentFeatures } from '@/theme';

import styles from './Orders.module.css';

export const Orders = () => {
  const { data } = useCutlists();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<OrderFilterOption>('all');
  const { createNewCutlist, isPending } = useCreateEmptyCutlist();

  const cancelled = data.filter((cutlist) => Boolean(cutlist.cancelledAt));
  const submitted = data.filter(
    (cutlist) => cutlist.submittedAt && !cutlist.cancelledAt
  );

  const drafts = data.filter(
    (cutlist) => !cutlist.submittedAt && !cutlist.cancelledAt
  );

  const showAll = filter === 'all';
  const showSubmitted = showAll || filter === 'submitted';
  const showDraft = showAll || filter === 'draft';
  const showCancelled = showAll || filter === 'cancelled';

  const ordersCount = {
    all: data.length,
    submitted: submitted.length,
    draft: drafts.length,
    cancelled: cancelled.length,
  };

  return (
    <div className="content">
      <section className={styles.layout}>
        <OrderFilter
          setFilter={setFilter}
          current={filter}
          ordersCount={ordersCount}
        />
        <div className="stack">
          <Button
            style={{
              width: '16rem',
            }}
            icon={<Icon icon={<Plus />} />}
            onClick={createNewCutlist}
            disabled={isPending}
          >
            {t('common.newOrder')}
          </Button>
          {showSubmitted && (
            <OrdersList orders={submitted} title={t('cutlists.submitted')} />
          )}
          {showDraft && (
            <OrdersList orders={drafts} title={t('cutlists.draft')} />
          )}
          {showCancelled && (
            <OrdersList orders={cancelled} title={t('cutlists.cancelled')} />
          )}
        </div>
      </section>
    </div>
  );
};

type OrdersListProps = {
  orders: CutlistOrder[];
  title: string;
};
const OrdersList = ({ orders, title }: OrdersListProps) => {
  const { t } = useTranslation();
  const [toDeleteCutlistId, setToDeleteCutlistId] = useState('');
  const [duplicatedCutlistId, setDuplicatedCutlistId] = useState('');
  const deleteDraft = useDeleteCutlist();
  const duplicateMutation = useDuplicateCutlist();
  const navigate = useNavigate();

  const handleDuplicate = async (cutlistId: string) => {
    const cutlist = await duplicateMutation.mutateAsync(cutlistId);
    setDuplicatedCutlistId(cutlist.id);
  };

  const handleDelete = (cutlistId: string) => {
    setToDeleteCutlistId(cutlistId);
  };

  if (orders.length === 0) return null;
  return (
    <div className="stack">
      <h4>{title}</h4>
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        orders.map((o) => {
          return (
            <OrderCard
              key={o.id}
              cutlist={o}
              onDuplicate={handleDuplicate}
              onDelete={handleDelete}
            />
          );
        })
      }

      <ConfirmationDialog
        title={t('cutlists.duplicationDialog.title')}
        onConfirm={() => {
          navigate(`/cutlist/${duplicatedCutlistId}/parts`);
        }}
        onClose={() => setDuplicatedCutlistId('')}
        isOpen={Boolean(duplicatedCutlistId)}
        confirmLabel={t('cutlists.duplicationDialog.confirmLabel')}
      >
        {t('cutlists.duplicationDialog.content')}
      </ConfirmationDialog>
      <ConfirmationDialog
        title={t('cutlists.confirmationDialog.title')}
        onConfirm={() => {
          deleteDraft.mutate(toDeleteCutlistId);
          setToDeleteCutlistId('');
        }}
        onClose={() => setToDeleteCutlistId('')}
        isOpen={Boolean(toDeleteCutlistId)}
        variant="alert"
      >
        {t('cutlists.confirmationDialog.content')}
      </ConfirmationDialog>
    </div>
  );
};

type OrderFilterOption = 'all' | 'submitted' | 'draft' | 'cancelled';

type OrderFilterProps = {
  setFilter: (to: OrderFilterOption) => void;
  current: OrderFilterOption;
  ordersCount: Record<OrderFilterOption, number>;
};

const OrderFilter = ({ setFilter, current, ordersCount }: OrderFilterProps) => {
  const { t } = useTranslation();
  const { requestQuoteEnabled } = useCurrentFeatures();

  const getStyle = (option: OrderFilterOption) => {
    return cn(styles.filterOption, option === current && styles.isActive);
  };

  return (
    <aside className="stack">
      <h2>{t(`cutlists.title`)}</h2>
      {(['all', 'submitted', 'draft', 'cancelled'] as OrderFilterOption[]).map(
        (o) => {
          const filterName =
            o !== 'submitted'
              ? o
              : requestQuoteEnabled
              ? 'submittedOrdersAndQuotes'
              : 'submittedOrders';
          return (
            <Button
              key={o}
              data-cy={`order-filter-${o}`}
              className={getStyle(o)}
              onClick={() => setFilter(o)}
            >
              {t(`cutlists.filter.${filterName}`, { count: ordersCount[o] })}
            </Button>
          );
        }
      )}
    </aside>
  );
};
