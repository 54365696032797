import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMaterialGroupErrorStore, usePartsErrorStore } from '@/api/errors';
import { getMaterial } from '@/api/materials';
import { useMaterialGroupState } from '@/api/materialsGroup';
import { selectHasMaterials, useCutlistState } from '@/api/store';
import { ConfirmationDialog } from '@/blocks/ConfirmationDialog';
import { Button } from '@/primitives/Button';
import { Alert, Icon, Layer, Trash } from '@/primitives/Icons';
import { useCurrentFeatures } from '@/theme';
import { getTrimConfigByType } from '@/utils/features';

import styles from './MaterialGroupTabNav.module.css';

export const MaterialGroupTabNav = () => {
  const tab = useMaterialGroupState((state) => state.activeGroup);
  const setTab = useMaterialGroupState((state) => state.setActive);
  const { addGroup, removeGroup } = useMaterialGroupState();
  const groups = useMaterialGroupState((state) => state.groups);
  const hasMaterials = useCutlistState(selectHasMaterials);
  const { materialGroupErrors, removeGroupErrors } =
    useMaterialGroupErrorStore();

  const select = (id: string) => () => setTab(id);
  const { t } = useTranslation();
  const { partErrors } = usePartsErrorStore();
  const [groupIdToRemove, setGroupIdToRemove] = useState('');
  const { supportedSheetEdgeTrims, defaultSheetEdgeTrimTypeForPartGroup } =
    useCurrentFeatures();

  const parts = useCutlistState((state) => state.parts);

  const onRemoveGroup = (groupId: string) => {
    window.analytics.track('Cutlist Material Group Deleted', { groupId });
    removeGroupErrors(groupId);
    removeGroup(groupId);
  };

  return (
    <section className={styles.tabNav}>
      {groups.map((g) => {
        const core1Material = getMaterial(g.core1);
        const coreName =
          g.sheetSizeSelection === 'automatic'
            ? `${core1Material?.variationGroup?.name || core1Material?.name} ${
                core1Material?.thicknessMM
              }mm`
            : core1Material?.name;
        const title = coreName || g.core1 || g.name;
        const partIds = parts
          .filter((part) => part.groupId === g.id)
          .map((p) => p.id);

        const partialCore = g.core1 && !core1Material;
        const pErrors = partErrors.some((p) => partIds.includes(p.partItemId));
        const errors = materialGroupErrors.some(
          (e) => e.materialGroupId === g.id
        );
        return (
          <div key={`tab-${g.id}`} className="flexAlign gap-xs">
            <Button
              role="tab"
              icon={
                partialCore || errors || pErrors ? (
                  <Icon icon={<Alert />} />
                ) : undefined
              }
              aria-selected={tab === g.id ? true : false}
              data-cy="tabButton"
              variant="tab"
              onClick={select(g.id)}
              className={tab === g.id ? styles.activeTab : styles.tab}
            >
              {title || t('cutlist-form.empty')}
            </Button>
            <Button
              variant="icon"
              className="delete"
              style={{
                border: '1px solid var(--gray-5)',
              }}
              icon={<Icon icon={<Trash />} />}
              onClick={() => setGroupIdToRemove(g.id)}
            />
          </div>
        );
      })}

      <Button
        disabled={!hasMaterials}
        name="add-group"
        icon={<Icon icon={<Layer />} />}
        onClick={() => {
          window.analytics.track('Cutlist Material Group Added');

          const sheetEdgeTrimConfig = getTrimConfigByType(
            supportedSheetEdgeTrims,
            defaultSheetEdgeTrimTypeForPartGroup
          );
          addGroup({ sheetEdgeTrimConfig });
        }}
        variant="secondaryPill"
      >
        {t('cutlist-form.cta.addGroup')}
      </Button>

      <ConfirmationDialog
        title={t('cutlist-form.confirmationDialog.title')}
        onConfirm={() => {
          onRemoveGroup(groupIdToRemove);
          setGroupIdToRemove('');
        }}
        onClose={() => setGroupIdToRemove('')}
        isOpen={!!groupIdToRemove}
        variant="alert"
      >
        {t('cutlist-form.confirmationDialog.content')}
      </ConfirmationDialog>
    </section>
  );
};
