import './App.css';

import * as Sentry from '@sentry/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createBrowserRouter,
  createRoutesFromElements,
  matchPath,
  Navigate,
  Outlet,
  Route,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import { api } from './api/backend';
import { useFetchFeatureFlags } from './api/featureFlags';
import { useAuthStore, useIsLoggedIn } from './api/login';
import { useCutlistState } from './api/store';
import CutrLabel from './blocks/CutrLabel';
import Header from './blocks/Header';
import {
  useApplyTheme,
  useInitCutlist,
  useLoginByKeyParam,
  useLoginByTokenParam,
  usePageViews,
  useThirdPartyLogin,
  useTogglePricing,
} from './hooks';
import AccountDetails from './pages/AccountDetails';
import Cutlist from './pages/Cutlist';
import { CutlistRoutes } from './pages/CutlistRoutes';
import Done from './pages/Done';
import { EditCutlistPage } from './pages/EditCutlist';
import EmailIntakeCutlist from './pages/EmailIntakeCutlist';
import { NotFound } from './pages/Errors';
import LandingPage from './pages/LandingPage';
import { OrderPage } from './pages/Order';
import OrderReview from './pages/OrderReview';
import { Orders } from './pages/Orders';
import { RouterButton } from './primitives/Button';
import { Illustration } from './primitives/Icons';
import { Configurator } from './stories/layout/Configurator';
import { CONFIGURATOR_ROUTE } from './stories/layout/types';
import { useCurrentFeatures, useThemeConfig } from './theme';
import {
  cleanupLocalStorage,
  iframePostMessage,
  isInIframe,
  usePostMessageResponder,
} from './utils/misc';
import { useLocationPageTitle } from './utils/page-title';

const PD = React.lazy(() => import('./pages/Playground'));
const DevPlayground = () => (
  <React.Suspense fallback={null}>
    <PD />
  </React.Suspense>
);

const CutlistConfigurator = () => (
  <React.Suspense fallback={null}>
    <Configurator />
  </React.Suspense>
);

const NoDisabledRoutesForSource = () => {
  const { allowedRoutes, source } = useThemeConfig();

  const location = useLocation();
  if (allowedRoutes) {
    const match = allowedRoutes.some((pattern) =>
      matchPath(pattern, location.pathname)
    );
    if (!match) {
      console.error('Route is not allowed for source', { location });
      Sentry.captureMessage('Route is not allowed for source', {
        level: 'error',
        extra: { source: source, pathname: location.pathname, allowedRoutes },
      });
      return <NoMatch />;
    }
  }
  return <Outlet />;
};

const OpenThirdPartyCutlist = () => {
  const { error } = useThirdPartyLogin();
  if (error) {
    return <NotFound />;
  }
  return null;
};

const LoginByToken = () => {
  useLoginByTokenParam();
  return null;
};

const LoginByKey = () => {
  useLoginByKeyParam();
  return null;
};

const PrivateRoutes = () => {
  const { allowUnauthUsers } = useCurrentFeatures();
  const isLoggedIn = useIsLoggedIn();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const pathname = location.pathname;

  if (allowUnauthUsers && pathname.includes('/parts')) {
    return <Outlet />;
  }

  if (!isLoggedIn) {
    const redirect = searchParams.get('redirect');
    if (!redirect && !['/', '/home', '/login'].includes(pathname)) {
      const redirectUrl = encodeURIComponent(pathname);
      searchParams.set('redirect', redirectUrl);
    }
    const path = `/login?${searchParams.toString()}`;
    return <Navigate to={path} />;
  }

  return <Outlet />;
};

const SessionRoutes = () => {
  const { login } = useAuthStore();
  const [configuratorToken, setConfiguratorToken] = React.useState<
    string | null
  >();

  React.useEffect(() => {
    const handleLoginBySession = async () => {
      const data = await api.loginBySession();
      const { email, clientNumber, token } = data;
      login({ email, clientNumber, token });
      setConfiguratorToken(token);
    };

    handleLoginBySession();
  }, []);

  return configuratorToken ? <Outlet /> : <NotFound />;
};

const App = () => {
  useInitCutlist();
  useFetchFeatureFlags();
  useTogglePricing();
  useApplyTheme();
  usePageViews();
  useLocationPageTitle();

  return <Outlet />;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route element={<NoDisabledRoutesForSource />}>
        <Route path="/" element={<Layout />}>
          <Route element={<PrivateRoutes />}>
            <Route path="cutlist/:id" element={<CutlistRoutes />}>
              <Route path="parts" element={<OrderPage />} />
              <Route path="details" element={<AccountDetails />} />
              <Route path="review" element={<OrderReview />} />
              <Route path="done" element={<Done />} />
              <Route index element={<Cutlist />} />
            </Route>

            <Route index element={<Orders />} />
          </Route>

          <Route path="home" element={<LandingPage />} />
          <Route path="login" element={<LandingPage />} />
          <Route path="new" element={<LoginByKey />} />
          <Route path="token" element={<LoginByToken />} />

          <Route path="open" element={<OpenThirdPartyCutlist />} />

          <Route
            path="free-form-cutlist/:id"
            element={<EmailIntakeCutlist />}
          />

          {/**
           * /edit route is still used because agents/admins need to login as the customer
           * before making changes to the cutlist
           *
           * In EditCutlistPage we hydrate the cutlist store then login as the customer
           */}
          <Route path="edit/:cutlistId" element={<EditCutlistPage />} />

          <Route path="*" element={<NoMatch />} />

          {import.meta.env.VITE_CUTR_ENV === 'development' && (
            <Route path="playground" element={<DevPlayground />} />
          )}
        </Route>
      </Route>
      {import.meta.env.VITE_CUTR_ENV !== 'production' && (
        <Route element={<SessionRoutes />}>
          <Route
            path={`/${CONFIGURATOR_ROUTE}`}
            element={<CutlistConfigurator />}
          >
            <Route
              path={`/${CONFIGURATOR_ROUTE}/:id`}
              element={<CutlistConfigurator />}
            />
          </Route>
        </Route>
      )}
    </Route>
  )
);

export default Sentry.withProfiler(App);

function Layout() {
  const { showCutrLabel } = useThemeConfig();

  const Wrapper = isInIframe() ? EmbeddedInIframe : React.Fragment;

  return (
    <>
      <Header />

      <Wrapper>
        <Outlet />
      </Wrapper>
      {showCutrLabel && (
        <footer>
          <div className="footer-content">
            <CutrLabel />
          </div>
        </footer>
      )}
    </>
  );
}

const EmbeddedInIframe = ({ children }: { children: React.ReactElement }) => {
  const { orderId, hasMaterials } = useCutlistState();

  usePostMessageResponder('save-cutlist', async (payload) => {
    if (!orderId) {
      throw 'Cutlist order id not found';
    }
    if (payload.id !== orderId) {
      throw 'Cutlist order id does not match!';
    }
    await api.updateCutlist(orderId);

    cleanupLocalStorage();
  });

  usePostMessageResponder('cleanup', async (payload) => {
    cleanupLocalStorage(payload.storageKey);
  });

  React.useEffect(() => {
    if (!hasMaterials) return;
    iframePostMessage('loading', { isReady: true });
  }, [hasMaterials]);

  return children;
};

function NoMatch() {
  const { t } = useTranslation();
  return (
    <div className="content" style={{ display: 'grid', placeItems: 'center' }}>
      <div style={{ maxWidth: '15rem', minWidth: '10rem' }}>
        <Illustration />
      </div>
      <h2>{t('translation.notfound.nothing')}</h2>
      <p>{t('translation.notfound.noexist')}</p>
      <p>
        <RouterButton to="/">{t('translation.done.homeCTA')}</RouterButton>
      </p>
    </div>
  );
}
