import { PartItem } from '@cutr/constants/cutlist';
import React from 'react';

import { usePartsErrorStore } from '@/api/errors';
import {
  updateAutomaticSheetSizeMaterials,
  useGroupById,
} from '@/api/materialsGroup';
import { selectActions, useCutlistState } from '@/api/store';

import { TableRowPanel } from './TableRowPanel';
import { TableRowSheet } from './TableRowSheet';
import { TableRowStrip } from './TableRowStrip';
import { cleanNumberInput } from './utils';

const MAX_PARTS = 10000;

export const TableRow = ({ part }: { part: PartItem }) => {
  const {
    partErrors: pErrors,
    addPartError,
    removePartErrors,
    removePartErrorsByField,
  } = usePartsErrorStore();
  const { setPart, removePart } = useCutlistState(selectActions);
  const group = useGroupById(part.groupId);
  const currentRow = React.useRef<HTMLTableRowElement>(null);
  const lastFocusedElementName = React.useRef<string | null>(null);

  React.useEffect(() => {
    const rowElement = currentRow.current as HTMLElement;
    if (!rowElement) return;
    const onFocusIn = (e: FocusEvent) => {
      if (rowElement.contains(e.target as HTMLElement)) {
        const target = e.target as HTMLInputElement;
        lastFocusedElementName.current = target?.name as string;
        target?.select?.();
      }
    };
    rowElement.addEventListener('focusin', onFocusIn);
    return () => {
      rowElement.removeEventListener('focusin', onFocusIn);
    };
  }, [currentRow.current]);

  React.useEffect(() => {
    const elementName = lastFocusedElementName.current;
    if (!elementName) return;
    const input = document.getElementsByName(
      elementName
    )[0] as HTMLInputElement;
    setTimeout(() => {
      input.focus();
      const stringEnd = input.value.length + 1;
      if (input.type === 'text') {
        input.setSelectionRange?.(stringEnd, stringEnd);
      }
    }, 0);
  }, [currentRow.current]);

  const onPartUpdate = (key: keyof PartItem, value: PartItem[typeof key]) => {
    const updatedPart = { ...part };
    // @ts-expect-error this typing is hard :/
    updatedPart[key] = value;
    setPart(updatedPart, group);

    // auto update sheet size when part doesn't fit on current sheet
    if (
      ['lengthMM', 'widthMM', 'grainDirection'].includes(key) &&
      group?.sheetSizeSelection === 'automatic'
    )
      updateAutomaticSheetSizeMaterials(group);
  };

  const onPartDelete = () => {
    removePartErrors(part.id);
    removePart(part);

    if (group?.sheetSizeSelection === 'automatic')
      updateAutomaticSheetSizeMaterials(group);
  };

  const validateNumericInput = (key: keyof PartItem, value: string) => {
    const number = cleanNumberInput(value);

    if (key === 'quantity') {
      removePartErrorsByField(part.id, key);
      if (+number > MAX_PARTS) {
        addPartError(
          part.id,
          key,
          `Part "${part.label}" quantity cannot be larger than ${MAX_PARTS}!`
        );
      }
    }
    return number;
  };

  const partErrors = pErrors.filter((error) => error.partItemId === part.id);

  const isFieldValid = (key: keyof PartItem) => {
    return partErrors.filter((error) => error.field === key).length === 0;
  };

  return (
    <>
      {part.partType === 'panel' && (
        <TableRowPanel
          ref={currentRow}
          part={part}
          hasError={Boolean(partErrors.length)}
          onPartUpdate={onPartUpdate}
          onPartDelete={onPartDelete}
          isFieldValid={isFieldValid}
          validateNumericInput={validateNumericInput}
        ></TableRowPanel>
      )}
      {part.partType === 'sheet' && (
        <TableRowSheet
          part={part}
          hasError={Boolean(partErrors.length)}
          onPartUpdate={onPartUpdate}
          onPartDelete={onPartDelete}
          isFieldValid={isFieldValid}
          validateNumericInput={validateNumericInput}
        ></TableRowSheet>
      )}

      {part.partType === 'strip' && (
        <TableRowStrip
          ref={currentRow}
          part={part}
          hasError={Boolean(partErrors.length)}
          onPartUpdate={onPartUpdate}
          onPartDelete={onPartDelete}
          isFieldValid={isFieldValid}
          validateNumericInput={validateNumericInput}
        ></TableRowStrip>
      )}
    </>
  );
};
