import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { useCurrentSource } from '@/theme';
import { getPersistedKey } from '@/utils/misc';

export type AuthState = {
  clientNumber: null | string;
  email: null | string;
  token: null | string;
};

type AuthStateActions = {
  login(data: AuthState): void;
  logout(): void;
};

const DEFAULT_AUTH_STATE = {
  clientNumber: null,
  email: null,
  token: null,
};

export const useAuthStore = create<AuthState & AuthStateActions>()(
  persist(
    (set) => ({
      ...DEFAULT_AUTH_STATE,
      login: (data) => set(() => ({ ...data })),
      logout: () => set((state) => ({ ...state, ...DEFAULT_AUTH_STATE })),
    }),
    { name: getPersistedKey('cutlist-auth') }
  )
);

export const isLoggedInSelector = (state: AuthState) => Boolean(state.token);
export const useIsLoggedIn = () => {
  const authStore = useAuthStore();
  const source = useCurrentSource();

  if (source === 'CUTR-EMBED') return true;

  return isLoggedInSelector(authStore);
};
