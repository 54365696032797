import { CutlistState, PartItem } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { api } from '@/api/backend';
import { materialsCached } from '@/api/materials';
import { Button } from '@/primitives/Button';

import styles from './EmailIntakeCutlist.module.css';

export const EmailIntakeCutlist = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { parts, init, reset } = useFreeFormCutlistState();
  const { id } = useParams();

  React.useEffect(() => {
    reset();
    loadFreeFormCutlist();
  }, []);

  const loadFreeFormCutlist = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const resp = await api.getFreeFormCutlist(id);
      init(resp);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmClick = async () => {
    if (!id) return;
    try {
      await api.markFreeFormCutlistCorrectness(id, true);
    } catch (e) {
      console.error(e);
    }
  };

  const onInvalidClick = async () => {
    if (!id) return;
    try {
      await api.markFreeFormCutlistCorrectness(id, false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <main className="content">
      <section>
        <h2>{t('upload-download.freeTextIntake.emailHeader')}</h2>

        {/** Place email contents here */}
      </section>

      <section>
        <div className={styles.freeTextIntakeContainer}>
          {isLoading && (
            <form className={styles.orderSection} name="ai-generated-parts">
              <SkeletonTable />
            </form>
          )}

          {parts.length > 0 && (
            <form className={styles.orderSection} name="ai-generated-parts">
              <section className={styles.partSection}>
                <table>
                  <Head headers={tableHeaders} />
                  <tbody>
                    {parts.map((part) => {
                      return <TableRow part={part} key={part.id} />;
                    })}
                  </tbody>
                </table>
                <div className="flexAlign">
                  <Button onClick={onInvalidClick} size="s" variant="secondary">
                    {t('upload-download.freeTextIntake.wrong')}
                  </Button>
                  <Button onClick={onConfirmClick} size="s">
                    {t('upload-download.freeTextIntake.correct')}
                  </Button>
                </div>
              </section>
            </form>
          )}
        </div>
      </section>
    </main>
  );
};

const tableHeaders = [
  { labelKey: 'field.partName.label', id: 'label', width: '80px' },
  { labelKey: 'field.quantity.label' },
  { labelKey: 'field.length.label' },
  { labelKey: 'field.width.label' },
];

export const DEFAULT_STORE = {
  title: '',
  orderId: undefined,
  parts: [],
  hasMaterials: materialsCached(),
  notes: '',
  customerReference: '',
  requestedDeliveryDate: '',
  deliverLeftoverMaterials: true,
};

const Head = ({ headers }: { headers: typeof tableHeaders }) => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        {headers.map((h, i) => (
          <th key={h.labelKey || i} style={{ minWidth: h.width }}>
            {t(`cutlist-form.${h.labelKey}`)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export type FreeFormCutlistState = CutlistState & FreeTextCutlistStateActions;

export type FreeTextCutlistStateActions = {
  init: (state: CutlistState) => void;
  reset(): void;
};

export const useFreeFormCutlistState = create<FreeFormCutlistState>()(
  devtools(
    (set) => ({
      ...DEFAULT_STORE,
      init: (savedState) => set((state) => ({ ...state, ...savedState })),
      reset: () =>
        set((state) => ({
          ...state,
          ...DEFAULT_STORE,
          hasMaterials: materialsCached(),
        })),
    }),
    { name: 'cutlist' }
  )
);

const TableRow = ({ part }: { part: PartItem }) => {
  return (
    <tr id={`partRow-${part.id}`}>
      <td>{part.label || ''}</td>
      <td>{part.quantity || ''}</td>
      <td>{part.lengthMM || ''}</td>
      <td>{part.widthMM || ''}</td>
    </tr>
  );
};

export const SkeletonTable = () => {
  const headers = tableHeaders;
  return (
    <section className={styles.partSection}>
      <table>
        <Head headers={headers} />
        <SkeletonBody headers={headers} />
      </table>
    </section>
  );
};

const SkeletonBody = ({ headers }: { headers: typeof tableHeaders }) => {
  return (
    <tbody>
      <SkeletonRow headers={headers} key={'part1'} />
      <SkeletonRow headers={headers} key={'part2'} />
    </tbody>
  );
};

const SkeletonRow = ({ headers }: { headers: typeof tableHeaders }) => (
  <tr>
    {headers.map((h) => {
      return (
        <td key={h.labelKey} className={styles.loading}>
          <div className={styles.bar}></div>
        </td>
      );
    })}
  </tr>
);

export default EmailIntakeCutlist;
