import {
  CutlistPartType,
  CutlistState,
  Edgebanding,
  EdgeProfile,
  Material,
  MaterialGroup,
  PartItem,
} from '@cutr/constants/cutlist';
import { EdgeProfileType } from '@cutr/constants/edge-profiling';
import { nanoid } from 'nanoid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getCurrentFeatures } from '@/theme';
import { isTruthy } from '@/utils/misc';
import { getSheetDimensions } from '@/utils/nesting';

import { getMaterial, materialsCached } from './materials';

const generatePartItem = (partItem?: Partial<PartItem>): PartItem => ({
  id: nanoid(6),
  groupId: '',
  label: '',
  quantity: 1,
  edgebanding: null,
  edgeProfile: {
    length1: 'none',
    length2: 'none',
    width1: 'none',
    width2: 'none',
  },
  grainDirection: 'none',
  createLabel: false,
  widthMM: 0,
  lengthMM: 0,
  thickness: 0,
  core1: null,
  core2: null,
  topHpl: null,
  bottomHpl: null,
  partType: 'panel',
  ...partItem,
});

export const DEFAULT_STORE = {
  title: '',
  orderId: undefined,
  shortId: undefined,
  parts: [],
  hasMaterials: materialsCached(),
  deliverLeftoverMaterials: true,
  notes: '',
  customerReference: '',
  requestedDeliveryDate: '',
};

export const selectActions = (state: CutlistStateActions) => {
  const { addPart, setPart, removePart, duplicatePart } = state;
  return { addPart, setPart, removePart, duplicatePart };
};

export const selectHasMaterials = (state: CutlistState) => state.hasMaterials;

export type CutlistStateActions = {
  init: (state: CutlistState) => void;

  setTitle: (title: string) => void;
  setId: (orderId: string) => void;
  addPart: (group: MaterialGroup, partType?: CutlistPartType) => void;
  removePartsByGroup: (id: MaterialGroup['id']) => void;
  addPartAfter: (index: number) => void;
  setPart: (part: PartItem, group?: MaterialGroup) => void;
  removePart: (part: PartItem) => void;
  duplicatePart: (part: PartItem) => void;
  setDeliverLeftoverMaterials: (setDeliverLeftoverMaterials: boolean) => void;
  setNotes: (notes: string) => void;
  setCustomerReference: (reference: string) => void;
  setRequestedDeliveryDate: (reference: string) => void;

  setHasMaterials(hasMaterials: boolean): void;
  reset(): void;
  replaceEdgeband(
    groupId: PartItem['groupId'],
    prev: string,
    next: string
  ): void;
  updatePartsFromGroup(group: MaterialGroup): void;
  replaceEdgeProfile(
    groupId: PartItem['groupId'],
    current: EdgeProfileType
  ): void;
  setCreateLabelForPartsInGroup(
    groupId: PartItem['groupId'],
    createLabel: boolean
  ): void;
};

const groupMaterialFormat = (group?: MaterialGroup): Partial<PartItem> => {
  const { id: groupId, core1, core2, topHpl, bottomHpl } = group || {};
  return {
    groupId,
    core1,
    core2,
    topHpl,
    bottomHpl,
  };
};

// OH BOY DO I HATE IMMUTABILITY IN JS
export const useCutlistState = create<CutlistState & CutlistStateActions>()(
  devtools(
    (set) => ({
      ...DEFAULT_STORE,
      addPart: (group, partType) => {
        const part = generatePartItem(groupMaterialFormat(group));
        const { thickness, width, length } = getSheetDimensions(group);

        set((state) => {
          if (partType === 'sheet') {
            part.lengthMM = length;
            part.widthMM = width;
            part.grainDirection = 'none';
          }
          if (partType === 'strip') {
            part.lengthMM = length;
            part.grainDirection = 'along';
          }

          part.thickness = thickness;
          part.partType = partType || 'panel';
          return {
            parts: [...state.parts, part],
          };
        });
        return part.id;
      },
      addPartAfter: (index) =>
        set((state) => ({
          parts: [
            ...state.parts.slice(0, index),
            generatePartItem(),
            ...state.parts.slice(index),
          ],
        })),
      setPart: (part, group) =>
        set((state) => {
          const newPart = { ...part };
          const newParts = state.parts.slice(0);
          const index = newParts.findIndex((p) => p.id === part.id);
          const { hasPartStrips } = getCurrentFeatures();

          if (group) {
            const { length } = getSheetDimensions(group);
            if (newPart.lengthMM === length && hasPartStrips) {
              newPart.partType = 'strip';
            }
            if (newPart.lengthMM < length) {
              newPart.partType = 'panel';
            }
          }

          if (index === -1) {
            newParts.push(newPart);
          }

          if (index >= 0) {
            newParts[index] = newPart;
          }

          return { parts: newParts };
        }),
      removePart: (part) =>
        set((state) => {
          const index = state.parts.findIndex((p) => p.id === part.id);
          return {
            parts: [
              ...state.parts.slice(0, index),
              ...state.parts.slice(index + 1),
            ],
          };
        }),
      replaceEdgeband: (groupId, prev, current) =>
        set((state) => {
          const parts = state.parts.map((part) => {
            if (part.groupId !== groupId || !part.edgebanding) {
              return part;
            }

            const edgebanding = {} as Edgebanding;
            Object.keys(part.edgebanding).forEach((key) => {
              if (!part.edgebanding) return;

              const k = key as keyof Edgebanding;
              edgebanding[k] = part.edgebanding[k];

              if (part.edgebanding[k] === prev) {
                edgebanding[k] = current;
              }
            });

            return { ...part, edgebanding };
          });
          return { parts };
        }),
      updatePartsFromGroup: (group) =>
        set((state) => {
          const { core1, core2, topHpl, bottomHpl } = group;
          const { thickness, width, length } = getSheetDimensions(group);

          const parts = state.parts.map((part) => {
            if (part.groupId !== group.id) {
              return part;
            }

            let updatedWidth = part.widthMM;
            let updatedLength = part.lengthMM;
            if (part.partType === 'sheet') {
              updatedWidth = width;
              updatedLength = length;
            }
            if (part.partType === 'strip') {
              updatedLength = length;
            }

            return {
              ...part,
              core1,
              core2,
              topHpl,
              bottomHpl,
              thickness,
              lengthMM: updatedLength,
              widthMM: updatedWidth,
            };
          });
          return { parts };
        }),
      replaceEdgeProfile: (groupId, current) =>
        set((state) => {
          const parts = state.parts.map((part) => {
            if (part.groupId !== groupId || !part.edgeProfile) {
              return part;
            }

            const edgeProfile = getNewEdgeProfile(part, current);

            return { ...part, edgeProfile };
          });
          return { parts };
        }),
      setCreateLabelForPartsInGroup: (groupId, createLabel) =>
        set((state) => {
          const parts = state.parts.map((part) => {
            if (part.groupId !== groupId) {
              return part;
            }

            return { ...part, createLabel };
          });
          return { parts };
        }),
      removePartsByGroup: (id) =>
        set((state) => ({
          parts: state.parts.filter((part) => part.groupId !== id),
        })),
      duplicatePart: (part) =>
        set((state) => {
          const index = state.parts.findIndex((p) => p.id === part.id);
          const newPart = {
            ...part,
            id: nanoid(),
            label: part.label + ' (copy)',
          };
          return {
            parts: [
              ...state.parts.slice(0, index + 1),
              newPart,
              ...state.parts.slice(index + 1),
            ],
          };
        }),

      setId: (orderId) => set(() => ({ orderId })),
      setTitle: (title) => set(() => ({ title })),
      setNotes: (notes) => set(() => ({ notes })),
      setDeliverLeftoverMaterials: (deliverLeftoverMaterials) =>
        set(() => ({ deliverLeftoverMaterials })),
      setCustomerReference: (customerReference) =>
        set(() => ({ customerReference })),
      setRequestedDeliveryDate: (requestedDeliveryDate) =>
        set(() => ({ requestedDeliveryDate })),
      setHasMaterials: (hasMaterials) => set(() => ({ hasMaterials })),

      init: (savedState) => set((state) => ({ ...state, ...savedState })),
      reset: () =>
        set((state) => ({
          ...state,
          ...DEFAULT_STORE,
          hasMaterials: materialsCached(),
        })),
    }),
    { name: 'cutlist' }
  )
);

const getNewEdgeProfile = (part: PartItem, newEdgeProfile: EdgeProfileType) => {
  const partEdgeProfile = part.edgeProfile;
  const emptyEdgeProfile = {
    length1: 'none',
    length2: 'none',
    width1: 'none',
    width2: 'none',
  } as EdgeProfile;

  if (!partEdgeProfile) {
    return emptyEdgeProfile;
  }

  if (part.partType === 'sheet') {
    return emptyEdgeProfile;
  }

  if (part.partType === 'strip') {
    return {
      length1: partEdgeProfile.length1 === 'none' ? 'none' : newEdgeProfile,
      length2: partEdgeProfile.length2 === 'none' ? 'none' : newEdgeProfile,
      width1: 'none',
      width2: 'none',
    } as EdgeProfile;
  }

  return {
    length1: partEdgeProfile.length1 === 'none' ? 'none' : newEdgeProfile,
    length2: partEdgeProfile.length2 === 'none' ? 'none' : newEdgeProfile,
    width1: partEdgeProfile.width1 === 'none' ? 'none' : newEdgeProfile,
    width2: partEdgeProfile.width2 === 'none' ? 'none' : newEdgeProfile,
  } as EdgeProfile;
};

export const useIsEditingCutlist = () =>
  useCutlistState((state) => Boolean(state.orderId));

// TODO: this is just to pass backend validation
export function isValidPart(part: PartItem) {
  return part.core1 && part.lengthMM > 0 && part.widthMM > 0;
}

const partsSelector = (state: CutlistState) => {
  const { parts } = state;
  return parts.map((part) => ({
    ...part,
    edgebanding: part.edgebanding || null,
    grainDirection: part.grainDirection || 'none',
  })) as PartItem[];
};

export const useCutlistParts = () => useCutlistState(partsSelector);

const coreMaterialSelector = (state: CutlistState) => {
  return state.parts
    .map((part) => part.core1)
    .filter((code, i, items) => code && items.indexOf(code) === i)
    .map((code) => getMaterial(code) as Material)
    .filter(isTruthy);
};
const hplMaterialSelector = (state: CutlistState) => {
  return state.parts
    .flatMap((part) => [part.topHpl, part.bottomHpl])
    .filter((code, i, items) => code && items.indexOf(code) === i)
    .map((code) => getMaterial(code) as Material)
    .filter(isTruthy);
};
const edgebandingMaterialSelector = (state: CutlistState) => {
  return state.parts
    .flatMap((part) => [...Object.values(part.edgebanding || {})])
    .filter((code, i, items) => code && items.indexOf(code) === i)
    .map((code) => getMaterial(code) as Material);
};

export const useUsedCoreMaterials = () => useCutlistState(coreMaterialSelector);
export const useUsedHplMaterials = () => useCutlistState(hplMaterialSelector);
export const useUsedBandingMaterials = () =>
  useCutlistState(edgebandingMaterialSelector);

const titleSelector = (state: CutlistState) => state.title;
export const useTitle = () => useCutlistState(titleSelector);

const setTitleSelector = (state: CutlistStateActions) => state.setTitle;
export const useSetTitle = () => useCutlistState(setTitleSelector);

const orderIdSelector = (state: CutlistState) => state.orderId;
export const useOrderId = () => useCutlistState(orderIdSelector);

type IsSaving = {
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
};

export const useSavingDraftStore = create<IsSaving>()((set) => ({
  isSaving: false,
  setIsSaving: (isSaving) => {
    set(() => ({ isSaving }));
  },
}));
