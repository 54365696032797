import { PartItem } from '@cutr/constants/cutlist';
import { create } from 'zustand';

type NestingErrors = {
  nestingErrors: string[];
  addError(error: string): void;
  resetErrors(): void;
};

export const useNestingErrorStore = create<NestingErrors>((set) => ({
  nestingErrors: [],
  addError: (error) =>
    set((state) => ({ nestingErrors: [...state.nestingErrors, error] })),
  resetErrors: () => set(() => ({ nestingErrors: [] })),
}));

type PartError = {
  partItemId: string;
  field: keyof PartItem | 'nesting';
  errorMessage: string;
};

type PartErrors = {
  partErrors: PartError[];
  resetPartsNestingError(): void;
  addPartError(
    partItemId: string,
    field: PartError['field'],
    error: string
  ): void;
  removePartErrorsByField(partItemId: string, field: PartError['field']): void;
  removePartErrors(partItemId: string): void;
};

export const usePartsErrorStore = create<PartErrors>((set) => ({
  partErrors: [],
  resetPartsNestingError: () =>
    set((state) => ({
      ...state,
      partErrors: state.partErrors.filter((pE) => pE.field !== 'nesting'),
    })),
  addPartError: (partItemId, field, errorMessage) =>
    set((state) => ({
      partErrors: state.partErrors
        .filter((err) => err.partItemId !== partItemId || err.field !== field)
        .concat({
          partItemId,
          field,
          errorMessage,
        }),
    })),
  removePartErrorsByField: (partItemId: string, field: keyof PartItem) =>
    set((state) => ({
      partErrors: state.partErrors.filter(
        (partError) =>
          partError.partItemId !== partItemId || partError.field !== field
      ),
    })),
  removePartErrors: (partItemId: string) =>
    set((state) => ({
      partErrors: state.partErrors.filter(
        (partError) => partError.partItemId !== partItemId
      ),
    })),
}));

type MaterialGroupError = {
  materialGroupId: string;
  errorMessage: string;
};

type MaterialGroupErrors = {
  materialGroupErrors: MaterialGroupError[];
  addGroupError(materialGroupId: string, error: string): void;
  removeGroupErrors(materialGroupId: string): void;
};

export const useMaterialGroupErrorStore = create<MaterialGroupErrors>(
  (set) => ({
    materialGroupErrors: [],
    addGroupError: (materialGroupId, errorMessage) =>
      set((state) => ({
        materialGroupErrors: state.materialGroupErrors.concat({
          materialGroupId,
          errorMessage,
        }),
      })),
    removeGroupErrors: (materialGroupId: string) =>
      set((state) => ({
        materialGroupErrors: state.materialGroupErrors.filter(
          (materialGroupError) =>
            materialGroupError.materialGroupId !== materialGroupId
        ),
      })),
  })
);

export const usePartsHaveErrors = () => {
  const { materialGroupErrors } = useMaterialGroupErrorStore();
  const { partErrors } = usePartsErrorStore();
  const { nestingErrors } = useNestingErrorStore();

  return (
    materialGroupErrors.length > 0 ||
    partErrors.length > 0 ||
    nestingErrors.length > 0
  );
};

export const resetErrors = () => {
  useMaterialGroupErrorStore.setState({ materialGroupErrors: [] });
  usePartsErrorStore.setState({ partErrors: [] });
  useNestingErrorStore.setState({ nestingErrors: [] });
};
