import {
  Edgebanding as EdgebandingType,
  EdgeProfile as EdgeProfileType,
} from '@cutr/constants/cutlist';
import {
  CutlistPartType,
  MaterialGroup,
  PartItem,
} from '@cutr/constants/cutlist';
import { useTranslation } from 'react-i18next';

import { makeTitle, useMaterialGroupState } from '@/api/materialsGroup';
import { useAggregatedMaterials } from '@/api/pricing';
import { useCutlistParts, useCutlistState } from '@/api/store';
import Card from '@/blocks/Card';
import { EdgesTooltip } from '@/blocks/EdgesTooltip';
import { AggregatedMaterials } from '@/blocks/MaterialRow';
import { useReadOnlyHeaders } from '@/pages/Cutlist';
import {
  Checkmark,
  Dashboard,
  GrainHorizontal,
  GrainNone,
  Icon,
  Layer,
  Menu,
} from '@/primitives/Icons';
import { useCurrentFeatures } from '@/theme';

import { CutlistNotesRecap } from './CutlistNotesRecap';
import styles from './CutlistPartsRecap.module.css';
import tableStyles from './PartTable/styles.module.css';

export const CutlistPartsRecap = () => {
  const store = useCutlistState();

  return (
    <section className={styles.order}>
      <Card>
        <h3 style={{ textTransform: 'capitalize' }}>{store.title}</h3>

        <div className={styles.summary}>
          <GroupList />

          <CutlistNotesRecap
            customerReference={store.customerReference}
            notes={store.notes}
            deliverLeftoverMaterials={store.deliverLeftoverMaterials}
          />
        </div>
      </Card>
    </section>
  );
};

const GroupList = () => {
  const { hasEdgeProfiling } = useCurrentFeatures();
  const { t } = useTranslation();
  const groupsRecap = useGroupsRecap();

  return (
    <>
      {groupsRecap.map((group) => {
        const { initialGroup } = group;

        return (
          <article key={initialGroup.id}>
            <h4>{group.title}</h4>
            <AggregatedMaterials items={group.materials} />
            <p />
            {hasEdgeProfiling && initialGroup.edgeProfile && (
              <>
                {t('cutlist-form.edgeProfile')}:{' '}
                <strong>
                  {t(`cutlist-form.edgeProfiling.${initialGroup.edgeProfile}`)}
                </strong>
              </>
            )}
            <p />
            <div className={tableStyles.orderSection}>
              <PartTable g={initialGroup}></PartTable>
            </div>
            <GroupNotes notes={group.notes} />
          </article>
        );
      })}
    </>
  );
};

export const GroupNotes = ({
  notes,
}: {
  notes: { continuousGrain?: string; additionalProcessing?: string };
}) => {
  const { t } = useTranslation();
  const grain = notes.continuousGrain;
  const processing = notes.additionalProcessing;

  const items = [
    {
      title: t('cutlist-form.field.continuousGrain.title'),
      data: grain,
      id: 'grain',
    },
    {
      title: t('cutlist-form.field.additionalProcessing.title'),
      data: processing,
      id: 'processing',
    },
  ].filter(({ data }) => Boolean(data));

  return (
    <>
      {items.map((item) => (
        <p key={item.id}>
          <strong>{item.title}</strong>
          <br />

          <span>
            {item.data}
            <br />
          </span>
        </p>
      ))}
    </>
  );
};

const PartTable = ({ g }: { g: MaterialGroup }) => {
  return (
    <table>
      <Head />
      <Body group={g} />
    </table>
  );
};

const Head = () => {
  const headers = useReadOnlyHeaders();
  const {
    edgebandingUI: hasEdgebanding,
    hasEdgeProfiling,
    hasCreateLabels,
  } = useCurrentFeatures();
  const { t } = useTranslation();

  const headerText = (key?: string) => (key ? t(`cutlist-form.${key}`) : '');

  return (
    <thead>
      <tr>
        {headers
          .filter((h) => h.id !== 'index')
          .map((h, i) => {
            const edgesHeader = (
              <th
                key={h.labelKey || i}
                style={{ minWidth: h.width }}
                colSpan={h.colSpan}
              >
                <div>
                  {t(`cutlist-form.${h.labelKey}`)}
                  <EdgesTooltip />
                </div>
              </th>
            );

            switch (h.id) {
              case 'edgebanding':
                if (!hasEdgebanding) return null;
                return edgesHeader;
              case 'edgeProfile':
                if (!hasEdgeProfiling) return null;
                return edgesHeader;
            }

            return (
              <th
                key={h.labelKey || i}
                style={{ minWidth: h.width }}
                colSpan={1}
              >
                {headerText(h.labelKey)}
              </th>
            );
          })}
      </tr>
      {(hasEdgebanding || hasEdgeProfiling) && (
        <tr>
          <th />
          <th />
          <th />
          <th />

          <th>{t('cutlist-form.field.edgebanding.sides.l1')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.l2')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.w1')}</th>
          <th>{t('cutlist-form.field.edgebanding.sides.w2')}</th>
          <th />
          {hasCreateLabels === 'part' && <th></th>}
        </tr>
      )}
    </thead>
  );
};

const Body = ({ group }: { group: MaterialGroup }) => {
  const parts = useCutlistState((state) =>
    state.parts.filter((part) => part.groupId === group.id)
  );

  return (
    <tbody>
      {parts.map((part) => {
        return <TableRow part={part} key={part.id} />;
      })}
    </tbody>
  );
};

const TableRow = ({ part }: { part: PartItem }) => {
  const {
    edgebandingUI: hasEdgebanding,
    hasEdgeProfiling,
    hasCreateLabels,
  } = useCurrentFeatures();
  const { t } = useTranslation();
  const iconMap: Record<CutlistPartType, JSX.Element> = {
    panel: <Dashboard />,
    sheet: <Layer />,
    strip: <Menu />,
  };

  const edgebandingSides: (keyof EdgebandingType)[] = [
    'length1',
    'length2',
    'width1',
    'width2',
  ];

  const edgeProfilingSides: (keyof EdgeProfileType)[] = [
    'length1',
    'length2',
    'width1',
    'width2',
  ];

  return (
    <tr id={`panelRow-${part.id}`}>
      <td>
        {
          <span className="flexAlign gap-xs">
            <Icon
              className={styles.unflex}
              icon={iconMap[part.partType] || <Dashboard />}
              color="var(--gray-7)"
            />
            {part.partType === 'sheet'
              ? t('cutlist-form.field.fullSheet')
              : part.label}
          </span>
        }
      </td>
      <td>
        <span>{part.quantity}</span>
      </td>
      <td>
        <span>{part.lengthMM}</span>
      </td>
      <td>
        <span>{part.widthMM}</span>
      </td>

      {hasEdgebanding &&
        edgebandingSides.map((side) => (
          <td key={side} style={{ color: 'var(--primary)' }}>
            {Boolean(part.edgebanding?.[side]) && (
              <Icon icon={<Checkmark />}></Icon>
            )}
          </td>
        ))}

      {hasEdgeProfiling &&
        edgeProfilingSides.map((side) => {
          const edgeProfile = part.edgeProfile?.[side];
          return (
            <td key={side} style={{ color: 'var(--primary)' }}>
              {edgeProfile !== null && edgeProfile !== 'none' && (
                <Icon icon={<Checkmark />}></Icon>
              )}
            </td>
          );
        })}

      <td>
        {part.grainDirection === 'along' ? (
          <Icon icon={<GrainHorizontal />} />
        ) : (
          <Icon icon={<GrainNone />} />
        )}
      </td>
      {hasCreateLabels === 'part' && (
        <td>{part.createLabel && <Icon icon={<Checkmark />} />}</td>
      )}
    </tr>
  );
};

export const useGroupsRecap = () => {
  const parts = useCutlistParts();
  const items = useAggregatedMaterials();
  const groups = useMaterialGroupState((s) => s.groups);

  return groups.map((group: MaterialGroup) => {
    const edgebands = parts
      .filter((p) => group.id === p.groupId)
      .filter((p) => p.edgebanding)
      .flatMap((p) => Object.values(p.edgebanding || {}));

    const materials = items.filter((i) =>
      [...edgebands, group.bottomHpl, group.topHpl, group.core1, group.core2]
        .filter(Boolean)
        .includes(i.articleCode)
    );

    const title = makeTitle(group);

    const notes = {
      continuousGrain: group.continuousGrain,
      additionalProcessing: group.additionalProcessing,
    };

    return {
      initialGroup: group,
      title,
      notes,
      materials,
    };
  });
};
