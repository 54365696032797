import React, { FormEventHandler, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { api } from '@/api/backend';
import { useIsLoggedIn } from '@/api/login';
import { Button } from '@/primitives/Button';
import { Input } from '@/primitives/Input';

import { ErrorMessage } from './ErrorMessage';
import { LoginModal } from './LoginModal';
import { Modal } from './Modal';
import styles from './RequestUnauthQuoteFlow.module.css';

export const RequestUnauthQuoteFlow = ({
  closeRequestFlow,
}: {
  closeRequestFlow: () => void;
}) => {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const params = useParams();
  const [error, setError] = React.useState('');

  const [isRequestQuoteModalOpen, setIsRequestQuoteModalOpen] =
    React.useState(true);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = React.useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');

  const { t } = useTranslation();

  const cutlistId = params.id;

  // when user correctly logs in, close the unauth request flow
  useEffect(() => {
    isLoggedIn && closeRequestFlow();
  }, [isLoggedIn]);

  const handleRedirectOnClose = () => {
    closeRequestFlow();
    navigate('/login?force=true');
  };

  const handleLoginModalOnClose = () => {
    setIsLoginModalOpen(false);
    setIsRequestQuoteModalOpen(true);
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    setError('');

    const data = new FormData(e.currentTarget);
    const email = data.get('email');
    setEmail(email as string);

    api
      .findOrCreateUserLead(email as string, cutlistId as string)
      .then(({ status, email }) => {
        setIsRequestQuoteModalOpen(false);

        if (status === 'created' || status === 'incomplete') {
          setIsThankYouModalOpen(true);
          window.analytics.track('New user lead requested quote', {
            email,
          });
        }

        if (status === 'exists') {
          setIsLoginModalOpen(true);
          window.analytics.track('Existing user lead requested quote', {
            email,
          });
        }
      })
      .catch((e) => {
        setError(t(`landing-page.login.errors.notAvailable`));
        console.error(e);
      });
  };

  return (
    <>
      <RequestUnauthQuoteModal
        isOpen={isRequestQuoteModalOpen}
        setOpen={setIsRequestQuoteModalOpen}
        onCloseRequestFlow={closeRequestFlow}
        setThankYouModalOpen={setIsThankYouModalOpen}
        setLoginModalOpen={setIsLoginModalOpen}
        onSubmit={onSubmit}
        error={error}
      />
      <ThankYouModal
        isOpen={isThankYouModalOpen}
        onClose={handleRedirectOnClose}
      />
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={handleLoginModalOnClose}
        email={email}
      />
    </>
  );
};

export const RequestUnauthQuoteModal = ({
  onCloseRequestFlow,
  setOpen,
  isOpen,
  setLoginModalOpen,
  onSubmit,
  error,
}: {
  onCloseRequestFlow: () => void;
  setOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  setThankYouModalOpen: (isOpen: boolean) => void;
  setLoginModalOpen: (isOpen: boolean) => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  error: string;
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'unauth-quote',
  });

  const handleLoginModalOnOpen = () => {
    setLoginModalOpen(true);
    setOpen(false);
  };

  return (
    <Modal title="" isOpen={isOpen} handleClose={onCloseRequestFlow}>
      <div className={styles.modalContent}>
        <div>
          <h2>{t('welcomeToBNB')}</h2>
          <p>{t('instructions')}</p>
          {Boolean(error) && <ErrorMessage message={error} />}
        </div>

        <form
          className={styles.formContainer}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          <div>
            <label htmlFor="email">
              {t(`email`)} {'*'}
            </label>
            <Input type="email" name="email" required />
          </div>
          <div className="stack gap-xxs">
            <Button type="submit">{t('submitRequest')}</Button>
            <div className="flexAlign gap-xxs">
              {t('alreadyCustomer')}{' '}
              <Button
                variant={'link'}
                className={'smaller'}
                onClick={handleLoginModalOnOpen}
              >
                {t('signInPrompt')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const ThankYouModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'unauth-quote',
  });
  return (
    <Modal handleClose={onClose} title="" isOpen={isOpen}>
      <div className={styles.modalContent}>
        <div className="">
          <h2>{t('thankYou')}</h2>
          <p>{t('nextSteps')}</p>
        </div>
        <Button onClick={onClose} style={{ maxWidth: '10rem' }}>
          {t('close')}
        </Button>
      </div>
    </Modal>
  );
};
