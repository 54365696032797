import { Material, PricingLineItem } from '@cutr/constants/cutlist';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getMaterialById } from './materials';

type PricingStore = {
  pricingLineItems: PricingLineItem[];
  totalAmountExclVAT: number;
  totalAmountInclVAT: number;
  error: Error | null;
  showPricing: boolean;
  manualQuote: boolean;
};

type PricingStoreActions = {
  setPricing(lineItems: PricingLineItem[]): void;
  setAmountVat(total: number): void;
  setAmountNoVat(total: number): void;
  setError(error: Error | null): void;
  togglePricing(showPricing: boolean): void;
  toggleManualQuote(manualQuote: boolean): void;
  reset(): void;
};

const DEFAULT_STORE = {
  pricingLineItems: [],
  totalAmountExclVAT: 0,
  totalAmountInclVAT: 0,
  error: null,
  showPricing: true,
  manualQuote: false,
};

export const usePricingStore = create<PricingStore & PricingStoreActions>()(
  devtools((set) => ({
    ...DEFAULT_STORE,
    setPricing: (pricingLineItems) => set(() => ({ pricingLineItems })),
    setAmountVat: (totalAmountInclVAT) => set(() => ({ totalAmountInclVAT })),
    setAmountNoVat: (totalAmountExclVAT) => set(() => ({ totalAmountExclVAT })),
    setError: (error) => set(() => ({ error })),
    togglePricing: (showPricing = false) => set(() => ({ showPricing })),
    toggleManualQuote: (manualQuote = false) => set(() => ({ manualQuote })),
    reset: () => set(() => ({ ...DEFAULT_STORE })),
  }))
);

type AggregateItems = { [key: PricingLineItem['category']]: PricingLineItem[] };

export type PriceData = {
  category: string;
  label: string;
  cost?: number;
  items?: PricingLineItem[];
};

const aggregatedPriceSelector = (state: PricingStore): PriceData[] => {
  const { pricingLineItems } = state;
  return aggregatePrices(pricingLineItems);
};

export const aggregatePrices = (pricingLineItems?: PricingLineItem[]) => {
  if (!pricingLineItems) return [];
  const aggregates = pricingLineItems.reduce((aggregate, item) => {
    if (!aggregate[item.category]) aggregate[item.category] = [];
    aggregate[item.category].push(item);

    return aggregate;
  }, {} as AggregateItems);
  return Object.keys(aggregates).map((label) => ({
    category: label,
    label: aggregates[label][0].categoryName,
    cost: aggregates[label].reduce((tot, item) => (tot += item.totalAmount), 0),
    items: aggregates[label],
  }));
};

export const useAggregatedPrice = () =>
  usePricingStore(aggregatedPriceSelector);

const aggregatedMaterialsSelector = (state: PricingStore) => {
  const { pricingLineItems } = state;
  return lineItemsToMaterials(pricingLineItems);
};

export const lineItemsToMaterials = (pricingLineItems?: PricingLineItem[]) => {
  if (!pricingLineItems) return [];
  return pricingLineItems
    .filter((item) => item.materialId && item.category === 'material')
    .map(({ materialId }) => {
      const material = getMaterialById(materialId) as Material;

      return material;
    });
};

export const useAggregatedMaterials = () =>
  usePricingStore(aggregatedMaterialsSelector);

const priceSelector = (state: PricingStore) => state.totalAmountExclVAT;
export const usePriceExVat = () => usePricingStore(priceSelector);

export const conditionallyTogglePricing = (hasPricing: boolean) => {
  const state = usePricingStore.getState();
  if (hasPricing === state.showPricing) return;
  state.togglePricing(hasPricing);
};
