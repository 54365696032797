import { CutlistState } from '@cutr/constants/cutlist';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  useLeadDetails,
  usePricingFromLeadDetails,
  UserLeadState,
} from '@/api/account';
import { Address, useDeliveryAddress } from '@/api/address';
import { api } from '@/api/backend';
import { useAuthStore } from '@/api/login';
import { useCanProceedToReview } from '@/api/navigation';
import { useCutlistState, useIsEditingCutlist } from '@/api/store';
import { ErrorMessage } from '@/blocks/ErrorMessage';
import { InfoMessage } from '@/blocks/InfoMessage';
import { EstimatedPrice } from '@/blocks/Price';
import { StepNavigation } from '@/blocks/StepNavigation';
import { Input, InputProps, Textarea } from '@/primitives/Input';
import { Select } from '@/primitives/Select';
import { useCurrentFeatures, useCurrentSource, useThemeConfig } from '@/theme';
import {
  formatYYYYMMDD,
  getNthWorkingDayFromToday,
  isWeekend,
} from '@/utils/date';
import {
  CountryCode,
  countryLabelToCode,
  getCountriesList,
} from '@/utils/intl';

import styles from './AccountDetails.module.css';

type ClientDetails = {
  clientNumber: null | string;
  email: null | string;
  name: null | string;
  companyName: null | string;
  phone: null | string;
  defaultDeliveryAddress: null | {
    street: null | string;
    postalCode: null | string;
    city: null | string;
    country: null | string;
  };
  userLeadId: null | string;
};

const AccountDetails = () => {
  const isEditing = useIsEditingCutlist();
  const { t } = useTranslation();
  const store = useLeadDetails();
  const cutlistStore = useCutlistState();
  const auth = useAuthStore();
  const { source } = useThemeConfig();
  const {
    prefillDeliveryAddress,
    hasRequestedDeliveryDate,
    deliverLeftoverMaterialInput,
  } = useCurrentFeatures();
  const isBnb = source === 'BNB';

  const { setProp: setDeliveryProp, ...deliveryAddress } = useDeliveryAddress();

  React.useEffect(() => {
    if (!auth.token) {
      store.setProp('notClient', true);
      return;
    }

    store.setProp('email', auth.email as string);
    store.setProp('clientNumber', auth.clientNumber as string);

    // We re-fetch client details from BnB API to make sure we have the latest
    // information for users who have already logged in.
    if (!auth.clientNumber || !auth.email) return;
    api.getClientDetails(auth.clientNumber, auth.email, source).then((data) => {
      const clientDetails = data as ClientDetails;

      store.setProp('id', clientDetails.userLeadId as string);
      store.setProp('name', clientDetails.name as string);
      store.setProp('companyName', clientDetails.companyName as string);

      if (prefillDeliveryAddress) {
        if (!deliveryAddress.contactName) {
          setDeliveryProp('contactName', clientDetails.name as string);
        }

        if (!deliveryAddress.phone) {
          setDeliveryProp('phone', clientDetails.phone as string);
        }

        if (!deliveryAddress.line1) {
          setDeliveryProp(
            'line1',
            clientDetails.defaultDeliveryAddress?.street as string
          );
        }

        if (!deliveryAddress.postalCode) {
          setDeliveryProp(
            'postalCode',
            clientDetails.defaultDeliveryAddress?.postalCode as string
          );
        }

        if (!deliveryAddress.city) {
          setDeliveryProp(
            'city',
            clientDetails.defaultDeliveryAddress?.city as string
          );
        }

        if (!deliveryAddress.country) {
          setDeliveryProp(
            'country',
            countryLabelToCode(
              clientDetails.defaultDeliveryAddress?.country as string
            )
          );
        }
      }
    });
  }, [auth]);

  const minDate = formatYYYYMMDD(getNthWorkingDayFromToday(3));

  React.useEffect(() => {
    const input = document.querySelector(
      'input#requestedDeliveryDate'
    ) as HTMLFormElement;

    if (!input) return;

    const listener = () => {
      if (isWeekend(input.value)) {
        input.setCustomValidity(
          t('account-details.validation.weekendDelivery')
        );
        input.reportValidity();
      }

      if (!isWeekend(input.value)) {
        input.setCustomValidity('');
      }
    };

    input.addEventListener('change', listener);
    return () => {
      input.removeEventListener('change', listener);
    };
  }, []);

  return (
    <div className="content">
      <Header />

      <section className="layout">
        <main className={styles.form}>
          <form name="details" id="accountDetails">
            <fieldset name="order">
              <h3>{t('account-details.order.title')}</h3>
              {hasRequestedDeliveryDate && (
                <Field
                  label={t('review.requestedDeliveryDate.title')}
                  className={styles.dateInput}
                  type="date"
                  name="requestedDeliveryDate"
                  min={minDate}
                  value={cutlistStore.requestedDeliveryDate || ''}
                  setProp={(value) => {
                    cutlistStore.setRequestedDeliveryDate(value);
                  }}
                  isRequired
                />
              )}
              {deliverLeftoverMaterialInput && (
                <div style={{ width: '50%' }}>
                  <label
                    className="flexAlign stack"
                    style={{ fontWeight: 600, margin: '0 0 1.5rem' }}
                  >
                    <div className="flexAlign">
                      {t('review.deliverLeftover.title')}
                      <input
                        name="deliverLeftoverMaterials"
                        checked={cutlistStore.deliverLeftoverMaterials}
                        type="checkbox"
                        onChange={(e) => {
                          cutlistStore.setDeliverLeftoverMaterials(
                            e.target.checked
                          );
                        }}
                      />
                    </div>
                    <InfoMessage
                      message={t('review.leftoverMaterials.disclaimer')}
                    />
                  </label>
                </div>
              )}

              <Field
                className={styles.fullWidth}
                label={t('review.customerReference.title')}
                name="customerReference"
                type="text"
                setProp={(value) => {
                  cutlistStore.setCustomerReference(value);
                }}
                value={cutlistStore.customerReference || ''}
                maxLength={255}
              />

              <div className={styles.field}>
                <label htmlFor="notes">{t('review.notes.title')}</label>
                <Textarea
                  className={styles.fullWidth}
                  name="notes"
                  onInput={(e) => cutlistStore.setNotes(e.currentTarget.value)}
                  value={cutlistStore.notes || ''}
                  maxLength={255}
                  placeholder={t('review.notes.description')}
                  rows={4}
                />
              </div>
            </fieldset>

            <fieldset name="details">
              <h3>{t('account-details.details.title')}</h3>
              <div className="flexAlign">
                <Field
                  label={t('account-details.field.name.label')}
                  name="name"
                  setProp={(value) => {
                    store.setProp('name', value);
                  }}
                  value={store['name']}
                  readOnly={Boolean(store.name)}
                  isRequired
                />
                <Field
                  label={t('account-details.field.companyName.label')}
                  name="companyName"
                  setProp={(value) => {
                    store.setProp('companyName', value);
                  }}
                  readOnly={Boolean(store.companyName)}
                  value={store['companyName']}
                />
              </div>

              {isBnb && (
                <div className="flexAlign gap-xs">
                  <Field
                    label={t('account-details.field.clientNumber.label', {
                      companyName: 'Baars & Bloemhoff',
                    })}
                    name="clientNumber"
                    setProp={(value) => {
                      store.setProp('clientNumber', value);
                    }}
                    value={store['clientNumber']}
                    isRequired
                    readOnly={isEditing}
                  />
                </div>
              )}
              <Field
                label={t('account-details.field.email.label')}
                name="email"
                type="email"
                readOnly={Boolean(auth.email) || isEditing}
                setProp={(value) => {
                  store.setProp('email', value);
                }}
                value={store['email']}
                isRequired
              />
            </fieldset>

            <DeliveryAddressForm
              title={t('account-details.delivery.title')}
              address={deliveryAddress}
              setProp={setDeliveryProp}
            />
          </form>
        </main>
        <Aside />
      </section>
    </div>
  );
};

const AutocompleteField = ({
  label = '',
  name,
  isRequired = false,
  countryCode = 'NL',
  setProp,
}: {
  countryCode: CountryCode;
} & FieldProp) => {
  const { i18n } = useTranslation();

  const options = getCountriesList(i18n.resolvedLanguage).map((country) => ({
    value: country.code,
    label: country.label,
    selected: country.code === countryCode,
  }));

  return (
    <div className={styles.field}>
      <label htmlFor={name}>
        {label} {isRequired && '*'}
      </label>
      <Select
        id={name}
        required={isRequired}
        options={options}
        onChange={(e) => setProp(e.currentTarget.value)}
      />
    </div>
  );
};

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="opposites">
      <h1>{t('account-details.opposites.title')}</h1>
      <StepNavigation />
    </div>
  );
};

const Aside = () => {
  usePricingFromLeadDetails();
  const source = useCurrentSource();
  const navigate = useNavigate();
  const [error, setError] = React.useState('');
  const canProceed = useCanProceedToReview();
  const { orderId } = useCutlistState();

  return (
    <aside>
      {Boolean(error) && <ErrorMessage message={error} />}
      <EstimatedPrice
        isNextDisabled={!canProceed}
        onAction={async () => {
          setError('');
          const isValid = (
            document.querySelector('form#accountDetails') as HTMLFormElement
          )?.checkValidity();
          if (!isValid) {
            setError('Fill in all required fields.');
            return;
          }

          navigate(`/cutlist/${orderId}/review`);
          window.analytics.track('Contact details filled', { ticker: source });
        }}
      />
    </aside>
  );
};

const DeliveryAddressForm = ({
  address,
  setProp,
  title = '',
}: {
  address: Address;
  setProp: (name: keyof Address, value: string) => void;
  title: string;
}) => {
  const { t } = useTranslation();

  return (
    <fieldset name="delivery">
      <h3>{title}</h3>
      <>
        <div className="flexAlign">
          <Field
            label={t('account-details.field.contactName.label')}
            name="contactName"
            setProp={(value) => setProp('contactName', value)}
            value={address['contactName']}
            isRequired
          />
          <Field
            label={t('account-details.field.phone.label')}
            name="phone"
            setProp={(value) => setProp('phone', value)}
            value={address['phone']}
            isRequired={true}
          />
        </div>
        <div className="flexAlign">
          <Field
            label={t('account-details.field.streetAddress.label')}
            name="line1"
            setProp={(value) => setProp('line1', value)}
            value={address['line1']}
            isRequired
          />
          <Field
            label={t('account-details.field.additions.label')}
            name="line2"
            setProp={(value) => setProp('line2', value)}
            value={address['line2']}
          />
        </div>
        <div className="flexAlign">
          <Field
            label={t('account-details.field.postalCode.label')}
            name="postalCode"
            setProp={(value) => setProp('postalCode', value)}
            value={address['postalCode']}
            isRequired
          />
          <Field
            label={t('account-details.field.city.label')}
            name="city"
            setProp={(value) => setProp('city', value)}
            value={address['city']}
            isRequired
          />
        </div>
        <AutocompleteField
          label={t('account-details.field.country.label')}
          name="country"
          setProp={(value) => setProp('country', value)}
          countryCode={address.country}
          isRequired
        />
      </>
    </fieldset>
  );
};

type FieldProp = {
  label: string;
  name: keyof UserLeadState | keyof Address | keyof CutlistState;
  isRequired?: boolean;
  isDisabled?: boolean;
  type?: 'text' | 'email' | 'date';
  value?: string;
  setProp: (value: string) => void;
  className?: string;
};

const Field = ({
  label = '',
  name,
  isDisabled = false,
  isRequired,
  type = 'text',
  setProp,
  className,
  ...props
}: FieldProp & InputProps) => {
  return (
    <div className={cn(styles.field, className)}>
      <label htmlFor={name}>
        {label} {isRequired && '*'}
      </label>
      <Input
        name={name}
        id={name}
        required={isRequired}
        type={type}
        disabled={isDisabled}
        onInput={(e) => {
          setProp(e.currentTarget.value);
        }}
        {...props}
      />
    </div>
  );
};

export default AccountDetails;
