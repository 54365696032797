import {
  MaterialGroup,
  MaterialSandwichType,
  sandwichHasDoubleCore,
  sandwichHasHPL,
  SheetEdgeTrimType,
} from '@cutr/constants/cutlist';
import { EdgeProfileType } from '@cutr/constants/edge-profiling';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { resetErrors } from '@/api/errors';
import { getMaterial } from '@/api/materials';
import {
  setGroupAndParts,
  useEdgebandingMaterialForGroup,
} from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import Card from '@/blocks/Card';
import { ConfirmationDialog } from '@/blocks/ConfirmationDialog';
import { PaintingSelect } from '@/interfaces/PaintingSelect';
import { Icon } from '@/primitives/Icons';
import { useCurrentFeatures } from '@/theme';
import {
  applyCustomTrimThickness,
  getTrimConfigByType,
} from '@/utils/features';

import { EdgeProfileDropdown } from './EdgeProfileDropdown';
import { LabelSettingDropdown } from './LabelSettingDropdown';
import { CustomEdgebandGroup } from './MaterialGroupCard';
import MaterialGroupCard from './MaterialGroupCard';
import styles from './MaterialGroupHeader.module.css';
import {
  MaterialSandwichSelect,
  sandwichIconMap,
} from './MaterialSandwichSelect';
import { SheetEdgeTrimDropdown } from './SheetEdgeTrimDropdown';
import { MaterialGroupUpdate } from './types';

export const MaterialGroupHeader = ({ group }: { group: MaterialGroup }) => {
  return (
    <>
      {group.materialSandwichType !== null && (
        <MaterialSelectionHeader group={group} />
      )}
      {group.materialSandwichType === null && (
        <SandwichSelectionHeader group={group} />
      )}
    </>
  );
};

const SandwichSelectionHeader = ({ group }: { group: MaterialGroup }) => {
  const { t } = useTranslation();
  const {
    supportedMaterialSandwiches,
    visibleGroupTypesOnSingleCore,
    defaultSheetEdgeTrimTypeForPartGroup,
    supportedSheetEdgeTrims,
  } = useCurrentFeatures();

  const options = supportedMaterialSandwiches.map((sandwichType) => ({
    sandwichType,
    label: t(`cutlist-form.materialSandwichTypes.${sandwichType}.label`),
    description: t(
      `cutlist-form.materialSandwichTypes.${sandwichType}.description`
    ),
  }));

  const onSandwichSelect = (sandwichType: MaterialSandwichType) => {
    const updatedGroup = { ...group, materialSandwichType: sandwichType };

    // If there is only one group type available for single-core
    if (
      visibleGroupTypesOnSingleCore.length <= 1 &&
      sandwichType === 'single-core'
    ) {
      updatedGroup.type = visibleGroupTypesOnSingleCore[0];
      updatedGroup.sheetEdgeTrimConfig = getTrimConfigByType(
        supportedSheetEdgeTrims,
        defaultSheetEdgeTrimTypeForPartGroup
      );
    }

    setGroupAndParts(updatedGroup);
  };

  React.useEffect(() => {
    if (options.length === 1) {
      onSandwichSelect(options[0].sandwichType);
    }
  }, [options]);

  return (
    <div className={styles.materialGroup}>
      <h3>{t('cutlist-form.chooseMaterialSandwich')}</h3>
      <div className={styles.sandwichSelectionGrid}>
        {options.map((option) => (
          <div
            key={option.sandwichType}
            className={styles.sandwichSelectionItem}
            onClick={() => onSandwichSelect(option.sandwichType)}
            role="button"
            tabIndex={0}
            aria-label={option.label}
            data-testid={option.sandwichType}
          >
            <div className={styles.sandwitchCardInfo}>
              <strong>{option.label}</strong>
              <p>{option.description}</p>
            </div>
            <div className={styles.sandwichIcon}>
              <Icon icon={sandwichIconMap[option.sandwichType]} size={8} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const MaterialSelectionHeader = ({ group }: { group: MaterialGroup }) => {
  const { t } = useTranslation();
  const {
    edgebandingUI,
    visibleGroupTypesOnSingleCore,
    supportedSheetEdgeTrims,
    defaultSheetEdgeTrimTypeForSheetGroup,
  } = useCurrentFeatures();
  const isSheetOnly = group.type === 'sheets-only';
  const { showGroupTypeSelect, hasProcessingSettings } =
    useProcessingSettingsConfig({ group });

  const { replaceEdgeband, addPart, replaceEdgeProfile } = useCutlistState();
  const groupParts = useCutlistState((state) =>
    state.parts.filter((part) => part.groupId === group.id)
  );

  type K = keyof MaterialGroup;
  const onMaterialGroupUpdate: MaterialGroupUpdate = (fields) => {
    let updatedGroup = { ...group } as MaterialGroup;

    const updatedKeys = Object.keys(fields) as K[];

    if (updatedKeys.includes('edgeband' as K)) {
      replaceEdgeband(
        group.id,
        group.edgeband || '',
        (fields.edgeband as string) || ''
      );
    }

    updatedGroup = { ...updatedGroup, ...fields };
    if (updatedKeys.includes('core1' as K)) {
      replaceEdgeProfile(group.id, 'none');

      const material = getMaterial(fields.core1);
      if (updatedGroup.type !== 'sheets-only') {
        applyCustomTrimThickness(updatedGroup, material);
      } else {
        const sheetEdgeTrimConfig = getTrimConfigByType(
          supportedSheetEdgeTrims,
          defaultSheetEdgeTrimTypeForSheetGroup
        );

        updatedGroup = { ...updatedGroup, sheetEdgeTrimConfig };
      }

      if (material && material.paintingAllowed !== true) {
        // clear painting if not supported by new material
        updatedGroup = {
          ...updatedGroup,
          paintColor: undefined,
          paintThicknessUM: undefined,
        };
      }
    }

    if (
      updatedKeys.includes('core1' as K) &&
      sandwichHasDoubleCore(updatedGroup.materialSandwichType)
    )
      updatedGroup['core2'] = fields.core1 as string | null;

    setGroupAndParts(updatedGroup);
    if (!groupParts.length && updatedGroup.type && updatedGroup.core1)
      addPart(updatedGroup);
    const materialKeys = [
      'core1',
      'core2',
      'topHpl',
      'bottomHpl',
      'edgeband',
    ] as K[];
    if (
      materialKeys.some((key: K) => fields[key] && group[key] !== fields[key])
    ) {
      const key = materialKeys.find((key: K) => fields[key]) as K;
      window.analytics.track('Cutlist Material Selected', {
        type: key,
        material: getMaterial(fields[key] as string),
      });
    }
  };

  const onSandwichTypeUpdate = (sandwichType: MaterialSandwichType) => {
    let updatedGroup = { ...group, materialSandwichType: sandwichType };

    if (!showGroupTypeSelect) {
      updatedGroup.type = visibleGroupTypesOnSingleCore[0];
    }

    if (sandwichType !== group.materialSandwichType) {
      // In every type of reset, we clear different set of materials
      if (sandwichType === 'single-core')
        updatedGroup = {
          ...updatedGroup,
          core2: null,
          topHpl: null,
          bottomHpl: null,
        };

      if (sandwichType === 'single-core-double-layer')
        updatedGroup = { ...updatedGroup, core2: null };

      if (sandwichType === 'double-core')
        updatedGroup = {
          ...updatedGroup,
          core2: group.core1, // duplicate core1
          topHpl: null,
          bottomHpl: null,
        };

      if (sandwichType === 'double-core-double-layer') {
        updatedGroup = { ...updatedGroup, core2: group.core1 }; // duplicate core1
      }
    }

    setGroupAndParts(updatedGroup);
  };

  const edgebandingMaterials = useEdgebandingMaterialForGroup();

  if (edgebandingMaterials.length == 1 && group.edgeband != undefined) {
    group.edgeband = edgebandingMaterials[0];
  }

  const edgebandingMaterialsCustom = edgebandingMaterials.length > 1;
  const hasHPL = sandwichHasHPL(group.materialSandwichType);
  const hasDoubleCore = sandwichHasDoubleCore(group.materialSandwichType);

  return (
    <div className={styles.materialGroup}>
      <h3>{t('cutlist-form.materialSettings')}</h3>

      <MaterialSandwichSelect
        group={group}
        onSandwichTypeUpdate={onSandwichTypeUpdate}
      />

      <div className={styles.materialsList}>
        {hasHPL && (
          <MaterialGroupCard
            i18nKey="layer1"
            field="topHpl"
            group={group}
            onMaterialGroupUpdate={onMaterialGroupUpdate}
          />
        )}

        <MaterialGroupCard
          i18nKey="core"
          field="core1"
          isDouble={hasDoubleCore}
          group={group}
          onMaterialGroupUpdate={onMaterialGroupUpdate}
        />

        {hasHPL && (
          <MaterialGroupCard
            i18nKey="layer2"
            field="bottomHpl"
            group={group}
            onMaterialGroupUpdate={onMaterialGroupUpdate}
          />
        )}

        {!isSheetOnly && edgebandingUI && (
          <MaterialGroupCard
            i18nKey="edgeband"
            field="edgeband"
            group={group}
            onMaterialGroupUpdate={onMaterialGroupUpdate}
          />
        )}
      </div>

      {edgebandingUI && edgebandingMaterialsCustom && (
        <>
          <h4>{t('cutlist-form.customEdgebandMaterials')}</h4>
          <div className={styles.materialsList}>
            <CustomEdgebandGroup group={group} />
          </div>
        </>
      )}

      {hasProcessingSettings && <h3>{t('cutlist-form.processingSettings')}</h3>}
      {showGroupTypeSelect && <MaterialGroupTypeSelect group={group} />}
      <MaterialGroupOptions group={group} />
    </div>
  );
};

const MaterialGroupTypeSelect = ({ group }: { group: MaterialGroup }) => {
  const { t } = useTranslation();
  const [typeToConfirm, confirmType] = React.useState<
    'panels-and-strips' | 'sheets-only' | undefined
  >(undefined);
  const {
    supportedSheetEdgeTrims,
    defaultSheetEdgeTrimTypeForPartGroup,
    defaultSheetEdgeTrimTypeForSheetGroup,
  } = useCurrentFeatures();

  const { addPart, removePartsByGroup } = useCutlistState();
  const groupParts = useCutlistState((state) =>
    state.parts.filter((part) => part.groupId === group.id)
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = e.target.value as MaterialGroup['type'];

    if (groupParts.length === 0) {
      updateMaterialGroupType(type);
      return;
    }

    // trigger confirmation modal
    confirmType(type);
  };

  const updateMaterialGroupType = (type: MaterialGroup['type']) => {
    const defaultGroupTrimType = (() => {
      if (type === 'sheets-only') return defaultSheetEdgeTrimTypeForSheetGroup;
      if (type === 'panels-and-strips')
        return defaultSheetEdgeTrimTypeForPartGroup;

      // return a default even if we were explicit above
      return defaultSheetEdgeTrimTypeForPartGroup;
    })();

    const sheetEdgeTrimConfig = getTrimConfigByType(
      supportedSheetEdgeTrims,
      defaultGroupTrimType
    );

    const updatedGroup = { ...group, type, sheetEdgeTrimConfig };

    const material = getMaterial(group.core1);
    if (type !== 'sheets-only') {
      applyCustomTrimThickness(updatedGroup, material);
    }

    resetGroup();

    const partType = type === 'panels-and-strips' ? 'panel' : 'sheet';
    addPart(updatedGroup, partType);

    setGroupAndParts(updatedGroup);
    resetErrors();
  };

  const resetGroup = () => {
    removePartsByGroup(group.id);
    confirmType(undefined);
  };

  return (
    <div className={styles.groupTypeGrid}>
      <input
        type="radio"
        name="groupType"
        id="panels-and-strips"
        className="hidden"
        value="panels-and-strips"
        checked={group.type === 'panels-and-strips'}
        onChange={handleChange}
      />
      <label htmlFor="panels-and-strips">
        <Card className={styles.groupTypeContent}>
          <strong>
            {t('cutlist-form.groupTypes.panels-and-strips.title')}
          </strong>
          {t('cutlist-form.groupTypes.panels-and-strips.description')}
        </Card>
      </label>

      <input
        type="radio"
        name="groupType"
        id="sheets-only"
        className="hidden"
        value="sheets-only"
        checked={group.type === 'sheets-only'}
        onChange={handleChange}
      />
      <label htmlFor="sheets-only">
        <Card className={styles.groupTypeContent}>
          <strong>{t('cutlist-form.groupTypes.sheets-only.title')}</strong>
          {t('cutlist-form.groupTypes.sheets-only.description')}
        </Card>
      </label>

      <ConfirmationDialog
        title={t('cutlist-form.cta.changeGroupType.title')}
        onConfirm={() => {
          updateMaterialGroupType(typeToConfirm!);
          confirmType(undefined);
        }}
        onClose={() => confirmType(undefined)}
        isOpen={!!typeToConfirm}
      >
        {t('cutlist-form.cta.changeGroupType.body')}
      </ConfirmationDialog>
    </div>
  );
};

const MaterialGroupOptions = ({ group }: { group: MaterialGroup }) => {
  const { t } = useTranslation();
  const { supportedSheetEdgeTrims } = useCurrentFeatures();
  const { replaceEdgeProfile, setCreateLabelForPartsInGroup } =
    useCutlistState();

  const {
    showEdgeTrimComponent,
    showEdgeProfiling,
    showPainting,
    showCreateLabels,
  } = useProcessingSettingsConfig({ group });

  const onEdgeProfileTypeUpdate = (edgeProfileType: EdgeProfileType) => {
    setGroupAndParts({
      ...group,
      edgeProfile: edgeProfileType,
    });

    replaceEdgeProfile(group.id, edgeProfileType);
  };

  const onLabelSettingUpdate = (createLabels: boolean) => {
    setGroupAndParts({
      ...group,
      createLabels,
    });

    setCreateLabelForPartsInGroup(group.id, createLabels);
  };

  const onSheetEdgeTrimTypeUpdate = (sheetEdgeTrimType: SheetEdgeTrimType) => {
    const sheetEdgeTrimConfig = getTrimConfigByType(
      supportedSheetEdgeTrims,
      sheetEdgeTrimType
    );

    const updatedGroup = {
      ...group,
      sheetEdgeTrimConfig,
    };

    setGroupAndParts(updatedGroup);
  };

  return (
    <div className={styles.optionsGroup}>
      <div className={styles.optionsBodyGrid}>
        {showEdgeProfiling && (
          <div>
            <h4>{t('cutlist-form.edgeProfile')}</h4>
            <EdgeProfileDropdown
              group={group}
              onEdgeProfileTypeUpdate={onEdgeProfileTypeUpdate}
              value={group.edgeProfile}
            />
          </div>
        )}
        {showPainting && (
          <div>
            <h4>{t('cutlist-form.painting')}</h4>
            <PaintingSelect />
          </div>
        )}
        {showEdgeTrimComponent && (
          <div>
            <h4>{t('cutlist-form.sheetEdges')}</h4>
            <SheetEdgeTrimDropdown
              group={group}
              onSheetEdgeTrimTypeUpdate={onSheetEdgeTrimTypeUpdate}
            />
          </div>
        )}
        {showCreateLabels && (
          <div>
            <h4>{t('cutlist-form.groupLabels.title')}</h4>
            <LabelSettingDropdown
              group={group}
              onLabelSettingUpdate={onLabelSettingUpdate}
              value={group.createLabels}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const useProcessingSettingsConfig = ({ group }: { group: MaterialGroup }) => {
  const {
    hasEdgeProfiling,
    showSheetEdgeTrimUi,
    hasPaintingOption,
    visibleGroupTypesOnSingleCore,
    hasCreateLabels,
  } = useCurrentFeatures();

  const isPartSawing = group.type === 'panels-and-strips';
  const groupHasMaterialAndType = group.type && group.core1;

  const showEdgeProfiling =
    groupHasMaterialAndType && isPartSawing && hasEdgeProfiling;
  const showEdgeTrimComponent = groupHasMaterialAndType && showSheetEdgeTrimUi;
  const showPainting = groupHasMaterialAndType && hasPaintingOption;
  const showCreateLabels =
    groupHasMaterialAndType && hasCreateLabels === 'group';

  const shouldShowGroupTypeSelect = () => {
    if (group.materialSandwichType !== 'single-core') return true;

    if (visibleGroupTypesOnSingleCore.length > 1) return true;

    return false;
  };

  const showGroupTypeSelect = shouldShowGroupTypeSelect();

  const hasProcessingSettings =
    showEdgeProfiling ||
    showEdgeTrimComponent ||
    showPainting ||
    showGroupTypeSelect;

  return {
    showEdgeProfiling,
    showEdgeTrimComponent,
    showPainting,
    showGroupTypeSelect,
    showCreateLabels,
    hasProcessingSettings,
  };
};
