import { CutlistOrder } from '@cutr/constants/cutlist';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { api } from '@/api/backend';
import { AuthState, useAuthStore, useIsLoggedIn } from '@/api/login';
import { useHydrateStore } from '@/hooks';
import { useThemeConfig } from '@/theme';
import { isInIframe } from '@/utils/misc';

export const EditCutlistPage = () => {
  const { cutlistId } = useParams();
  const { source } = useThemeConfig();
  const authStore = useAuthStore();
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  const { hydrateStore } = useHydrateStore();

  const [error, setError] = React.useState<string>();

  const [cutlistRestored, setCutlistRestored] = React.useState(false);
  const [loginRestored, setLoginRestored] = React.useState(false);

  const [searchParams] = useSearchParams();
  const materialId = searchParams.get('material');

  React.useEffect(() => {
    if (!cutlistId) {
      setError('Not found');
      return;
    }

    const isDraft = searchParams.get('draft') === 'true';

    api
      .cutlist(cutlistId, isDraft)
      .then((cutlist) => {
        const token = cutlist.token;
        if (token && source === 'VERDOUW') {
          authStore.login({ token } as AuthState);
          navigate(`/cutlist/${cutlistId}/parts`);
          return;
        }

        restoreState(cutlist);
      })
      .catch((e) => {
        setError(e?.message || 'Could not fetch cutlist');
      });
  }, []);

  const restoreState = (cutlist: CutlistOrder) => {
    hydrateStore(cutlist);

    if (isLoggedIn && !isInIframe()) {
      setLoginRestored(true);
      setCutlistRestored(true);
      return;
    }

    api
      .login(
        cutlist.userLeadDetail.clientNumber,
        cutlist.userLead.email,
        source
      )
      .then((data) => {
        authStore.login(data);
        setLoginRestored(true);
      })
      .catch((e) => {
        setError(e?.message || 'Login failed');
      });
    setCutlistRestored(true);
  };

  React.useEffect(() => {
    if (!cutlistRestored) return;
    if (!loginRestored) return;

    navigate(
      `/cutlist/${cutlistId}/parts` +
        (materialId ? `?material=${materialId}` : '')
    );
  }, [cutlistRestored, loginRestored]);

  return (
    <div className="content">
      {!error && (!cutlistRestored || !loginRestored) && <div>loading..</div>}
      {error && <div className="error">{error}</div>}
    </div>
  );
};
