import { SheetConfig } from '@cutr/constants/cutlist-nesting';

export function sheetOffset(sheetConfig: SheetConfig): {
  offsetX: number;
  offsetY: number;
} {
  return {
    offsetX:
      sheetConfig.sheetEdgeTrimConfig.trimThickness.width1TrimThicknessMM,
    offsetY:
      sheetConfig.sheetEdgeTrimConfig.trimThickness.length2TrimThicknessMM,
  };
}

export function sheetEdgeTrimmingMM(sheetConfig: SheetConfig): number {
  return (
    (sheetConfig.sheetEdgeTrimConfig.trimThickness.length1TrimThicknessMM > 0
      ? sheetConfig.lengthMM
      : 0) +
    (sheetConfig.sheetEdgeTrimConfig.trimThickness.length2TrimThicknessMM > 0
      ? sheetConfig.lengthMM
      : 0) +
    (sheetConfig.sheetEdgeTrimConfig.trimThickness.width1TrimThicknessMM > 0
      ? sheetConfig.widthMM
      : 0) +
    (sheetConfig.sheetEdgeTrimConfig.trimThickness.width2TrimThicknessMM > 0
      ? sheetConfig.widthMM
      : 0)
  );
}

export function sheetTrimmedDimensions(sheetConfig: SheetConfig): {
  lengthMM: number;
  widthMM: number;
} {
  return {
    lengthMM:
      sheetConfig.lengthMM -
      sheetConfig.sheetEdgeTrimConfig.trimThickness.width1TrimThicknessMM -
      sheetConfig.sheetEdgeTrimConfig.trimThickness.width2TrimThicknessMM,
    widthMM:
      sheetConfig.widthMM -
      sheetConfig.sheetEdgeTrimConfig.trimThickness.length1TrimThicknessMM -
      sheetConfig.sheetEdgeTrimConfig.trimThickness.length2TrimThicknessMM,
  };
}
