import { AddressPretty } from '@cutr/constants/cutlist-theme';
import {
  IframeMessagePayload,
  IframeMessageProps,
  PayloadTypes,
} from '@cutr/constants/iframe-communication';
import React from 'react';

import { Address } from '@/api/address';

import { getCountriesList } from './intl';

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T; // from lodash

export function isTruthy<T>(value: T): value is Truthy<T> {
  return Boolean(value);
}

export function urlParamToBoolean(param: string | null) {
  if (param == null) return;

  if (['true', '1', 1].includes(param)) {
    return true;
  }
  if (['false', '0', 0].includes(param)) {
    return false;
  }
}

export function copyToClipboard(textToCopy: string) {
  // TODO: claudio, re-enable this
  return Promise.resolve(true);

  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  }

  // alternative trick using the deprecated execCommand (mostly for dev)
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  // textarea must be outside of the viewport for this trick to work
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((res, rej) => {
    document.execCommand('copy') ? res(true) : rej();
    textArea.remove();
  });
}

export function addrToPretty(
  address: Address,
  lang = 'en',
  email?: string
): AddressPretty {
  const countryName =
    getCountriesList(lang).find((it) => it.code === address.country)?.label ||
    'Netherlands';

  return {
    name: address.contactName,
    address: [address.line1, address.line2, address.postalCode, address.city]
      .filter(Boolean)
      .join(', '),
    countryName: countryName,
    tel: address.phone,
    email,
  };
}

export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function usePostMessageResponder<MessageType extends keyof PayloadTypes>(
  messageType: MessageType,
  handler: (
    payload: PayloadTypes[MessageType],
    event: MessageEvent
  ) => Promise<unknown>
) {
  React.useEffect(() => {
    const listener = async (event: MessageEvent) => {
      const { msg, payload, __msgId: messageId } = event.data;
      if (msg !== messageType) return;

      let resultPromise;
      try {
        resultPromise = Promise.resolve(handler(payload, event));
      } catch (e) {
        resultPromise = Promise.reject(e);
      }
      if (messageId) {
        resultPromise = resultPromise
          .then((result) => ({ result }))
          .catch((err) => ({ err }))
          .then((payload) => {
            const targetOrigin = event.origin || '*';
            (event.source as Window).postMessage(
              { ...payload, __msgId: messageId },
              targetOrigin
            );
          });
      }
      await resultPromise;
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [messageType, handler]);
}

export function iframePostMessage<Key extends IframeMessagePayload['key']>(
  key: Key,
  payload: IframeMessageProps[Key]
) {
  if (!isInIframe()) return;
  if (!window.name) return;

  window.parent.postMessage(
    { __msgId: window.name, payload: { key, ...payload } },
    '*'
  );
}

export const cleanupLocalStorage = (storageSuffix?: string) => {
  try {
    Object.keys(localStorage).forEach((key) => {
      if (key.endsWith(storageSuffix || getPersistedKey(''))) {
        localStorage.removeItem(key);
      }
    });
  } catch (e) {
    console.error('no local storage');
  }
};

export function getPersistedKey(key: string) {
  const suffix = isInIframe() && window.name ? `:${window.name}` : '';
  return `${key}${suffix}`;
}

// currently formatting embed video urls only for youtube videos
export const formatEmbedVideoUrl = ({
  videoUrl,
}: {
  videoUrl: string;
}): string => {
  const embedUrlRoot = 'https://www.youtube.com/embed/';

  const videoId = videoUrl.split('v=')[1];

  return embedUrlRoot + videoId;
};
