import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Pagination.module.css';
type PaginationProps = {
  children: React.ReactElement[];
  navKey?: 'sheet' | 'material';
};
export const Pagination = ({ children, navKey = 'sheet' }: PaginationProps) => {
  const { t } = useTranslation();
  const container = React.useRef<HTMLDivElement>(null);
  const [page, setPage] = React.useState(1);
  const pages = React.Children.count(children);

  React.useEffect(() => {
    const isEmpty = pages === 0;
    const isOutOfBounds = page > pages;

    if (isEmpty || isOutOfBounds) {
      setPage(1);
      return;
    }
  }, [page, pages]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content} ref={container}>
        {React.Children.map(children, (child, i) => {
          const display = i + 1 === page ? 'block' : 'none';
          return React.cloneElement(child, {
            ...child.props,
            style: { display },
          });
        })}
      </div>
      {pages > 1 && (
        <div className={styles.navigationWrapper}>
          <p>{t(`translation.pagination.${navKey}`, { page, pages })}</p>
          <Navigation pages={pages} page={page} onClick={setPage} />
        </div>
      )}
    </div>
  );
};

const Navigation = ({
  pages,
  page,
  onClick,
}: {
  pages: number;
  page: number;
  onClick: (page: number) => void;
}) => {
  const next = page + 1;
  const prev = page - 1;

  const hasNext = next <= pages;
  const hasPrev = prev > 0;

  const onNext = () => {
    if (!hasNext) return;
    onClick(next);
  };
  const onPrev = () => {
    if (!hasPrev) return;
    onClick(prev);
  };

  return (
    <div className={styles.navigation}>
      {/* eslint-disable i18next/no-literal-string */}
      <button
        className={hasPrev ? undefined : styles.disabled}
        onClick={onPrev}
      >
        &laquo;
      </button>
      {[...new Array(pages)].map((_, i) => {
        const curPage = i + 1;
        return (
          <button
            key={`btn-${curPage}`}
            className={page === curPage ? styles.active : undefined}
            onClick={() => onClick(curPage)}
          >
            {curPage}
          </button>
        );
      })}
      <button
        className={hasNext ? undefined : styles.disabled}
        onClick={onNext}
      >
        &raquo;
      </button>
      {/* eslint-enable */}
    </div>
  );
};
