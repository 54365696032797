import { useTranslation } from 'react-i18next';

import { Button, RouterButton } from '@/primitives/Button';
import { Illustration } from '@/primitives/Icons';
import { useThemeConfig } from '@/theme';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <GenericErrorPage>
      <h3>{t('review.errors.notFound.title')}</h3>
      <p>{t('review.errors.notFound.body')}</p>
    </GenericErrorPage>
  );
};

export const Forbidden = () => {
  const { t } = useTranslation();

  return (
    <GenericErrorPage>
      <h3>{t('review.errors.accessDenied.title')}</h3>
      <p>{t('review.errors.accessDenied.body')}</p>
    </GenericErrorPage>
  );
};

const GenericErrorPage = ({
  children,
}: React.InputHTMLAttributes<HTMLInputElement>) => {
  const { t } = useTranslation();
  const { backBtnUrl, source } = useThemeConfig();

  return (
    <div
      className="content"
      style={{
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <p style={{ width: '15rem' }}>
        <Illustration />
      </p>
      <br />
      {children}
      <br />
      {source === 'VERDOUW' ? (
        <Button href={backBtnUrl} style={{ width: '18em' }} as="a">
          {t('review.goToHome')}
        </Button>
      ) : (
        <RouterButton to="/" style={{ width: '18em' }}>
          {t('review.goToHome')}
        </RouterButton>
      )}
    </div>
  );
};
