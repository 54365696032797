import './Button.css';

import cn from 'classnames';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

type ButtonVariant =
  | 'tab'
  | 'secondary'
  | 'icon'
  | 'iconHeader'
  | 'fullDelete'
  | 'header'
  | 'straight'
  | 'secondaryPill'
  | 'link';
type ButtonSize = 'xs' | 's' | 'l';

type ButtonCustomProps = {
  icon?: JSX.Element;
  tooltip?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
};
type ButtonProps<T extends React.ElementType> = ButtonCustomProps & {
  as?: T;
};

const variantsMap: Record<ButtonVariant, string> = {
  tab: 'smaller transparent',
  secondary: 'smaller straight outline',
  icon: 'smaller pill snug transparent',
  iconHeader: 'smaller pill snug transparent header',
  fullDelete: 'full transparent delete',
  header: 'outline smaller header',
  straight: 'smaller straight',
  secondaryPill: 'smaller pill outline',
  link: 'link',
};

const sizesMap: Record<ButtonSize, string> = {
  s: 'smaller',
  xs: 'smaller snug',
  l: 'larger',
};

function getClasses(
  variant: ButtonCustomProps['variant'],
  size: ButtonCustomProps['size'],
  className?: string
) {
  const variantClasses = variant && variantsMap[variant];
  const sizeClasses = size && sizesMap[size];
  return cn('button', variantClasses, sizeClasses, className);
}

export const Button = <T extends React.ElementType = 'button' | 'a'>({
  className,
  children,
  icon,
  tooltip,
  variant,
  size,
  as,
  ...props
}: ButtonProps<T> & React.ComponentPropsWithoutRef<T>) => {
  const hasTooltip = Boolean(tooltip);
  const Component = as || 'button';
  const isButton = Component === 'button';

  return (
    <Component
      className={cn(getClasses(variant, size, className), {
        tooltip: hasTooltip,
      })}
      data-tooltip={hasTooltip ? tooltip : undefined}
      {...props}
      type={isButton ? props.type || 'button' : undefined}
    >
      <span className="buttonContainer">
        <>
          {Boolean(icon) && icon}
          {children}
        </>
      </span>
    </Component>
  );
};

export const RouterButton = ({
  children,
  icon,
  className,
  variant,
  size,
  ...props
}: LinkProps & ButtonCustomProps) => (
  <Link className={getClasses(variant, size, className)} {...props}>
    <span className="buttonContainer">
      <>
        {Boolean(icon) && icon}
        {children}
      </>
    </span>
  </Link>
);
